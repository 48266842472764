import * as React from 'react';

import { SVGIconProps } from './types';

export const NotFoundIcon = ({ size = 24, ...rest }: SVGIconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 110 110"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-test-id="CT_component_not-found-icon_cms"
		{...rest}
	>
		<g clip-path="url(#clip0_3060_82535)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4 21.0135C4 16.3116 7.81163 12.5 12.5135 12.5H100.486C105.188 12.5 109 16.3116 109 21.0135V89.1216C109 93.8235 105.188 97.6351 100.486 97.6351H12.5135C7.81163 97.6351 4 93.8235 4 89.1216V21.0135ZM12.5135 18.1757C10.9462 18.1757 9.67568 19.4462 9.67568 21.0135V89.1216C9.67568 90.6889 10.9462 91.9595 12.5135 91.9595H100.486C102.054 91.9595 103.324 90.6889 103.324 89.1216V21.0135C103.324 19.4462 102.054 18.1757 100.486 18.1757H12.5135Z"
				fill="#E0E0E0"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M36.8713 47.0865C38.1444 48.0006 38.4355 49.7737 37.5215 51.0468L27.5039 64.9999H40.4189C41.9862 64.9999 43.2568 66.2704 43.2568 67.8377C43.2568 69.405 41.9862 70.6755 40.4189 70.6755H21.973C20.9087 70.6755 19.9339 70.0801 19.4481 69.1332C18.9623 68.1863 19.0471 67.0472 19.6677 66.1827L32.911 47.7367C33.825 46.4636 35.5981 46.1725 36.8713 47.0865Z"
				fill="#E0E0E0"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M35.6892 46.5542C37.2565 46.5542 38.527 47.8247 38.527 49.392V75.8785C38.527 77.4458 37.2565 78.7164 35.6892 78.7164C34.1219 78.7164 32.8513 77.4458 32.8513 75.8785V49.392C32.8513 47.8247 34.1219 46.5542 35.6892 46.5542Z"
				fill="#E0E0E0"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M46.5676 55.0675C46.5676 49.3208 51.2263 44.6621 56.973 44.6621C62.7198 44.6621 67.3784 49.3208 67.3784 55.0675V68.3108C67.3784 74.0575 62.7198 78.7162 56.973 78.7162C51.2263 78.7162 46.5676 74.0575 46.5676 68.3108V55.0675ZM56.973 50.3378C54.3609 50.3378 52.2433 52.4554 52.2433 55.0675V68.3108C52.2433 70.9229 54.3609 73.0405 56.973 73.0405C59.5852 73.0405 61.7028 70.9229 61.7028 68.3108V55.0675C61.7028 52.4554 59.5852 50.3378 56.973 50.3378Z"
				fill="#E0E0E0"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M87.9523 47.0865C89.2255 48.0006 89.5166 49.7737 88.6025 51.0468L78.5849 64.9999H91.5C93.0673 64.9999 94.3378 66.2704 94.3378 67.8377C94.3378 69.405 93.0673 70.6755 91.5 70.6755H73.054C71.9898 70.6755 71.015 70.0801 70.5292 69.1332C70.0433 68.1863 70.1281 67.0472 70.7488 66.1827L83.992 47.7367C84.9061 46.4636 86.6792 46.1725 87.9523 47.0865Z"
				fill="#E0E0E0"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M86.7702 46.5542C88.3375 46.5542 89.608 47.8247 89.608 49.392V75.8785C89.608 77.4458 88.3375 78.7164 86.7702 78.7164C85.2029 78.7164 83.9324 77.4458 83.9324 75.8785V49.392C83.9324 47.8247 85.2029 46.5542 86.7702 46.5542Z"
				fill="#E0E0E0"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M4 34.2568C4 32.6895 5.27054 31.4189 6.83784 31.4189H105.216C106.784 31.4189 108.054 32.6895 108.054 34.2568C108.054 35.8241 106.784 37.0946 105.216 37.0946H6.83784C5.27054 37.0946 4 35.8241 4 34.2568Z"
				fill="#E0E0E0"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0 21.0135C0 16.3116 3.81163 12.5 8.51351 12.5H96.4865C101.188 12.5 105 16.3116 105 21.0135V89.1216C105 93.8235 101.188 97.6351 96.4865 97.6351H8.51351C3.81163 97.6351 0 93.8235 0 89.1216V21.0135ZM8.51351 18.1757C6.94622 18.1757 5.67568 19.4462 5.67568 21.0135V89.1216C5.67568 90.6889 6.94622 91.9595 8.51351 91.9595H96.4865C98.0538 91.9595 99.3243 90.6889 99.3243 89.1216V21.0135C99.3243 19.4462 98.0538 18.1757 96.4865 18.1757H8.51351Z"
				fill="#111111"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M32.8713 47.0865C34.1444 48.0006 34.4355 49.7737 33.5215 51.0468L23.5039 64.9999H36.4189C37.9862 64.9999 39.2568 66.2704 39.2568 67.8377C39.2568 69.405 37.9862 70.6755 36.4189 70.6755H17.973C16.9087 70.6755 15.9339 70.0801 15.4481 69.1332C14.9623 68.1863 15.0471 67.0472 15.6677 66.1827L28.911 47.7367C29.825 46.4636 31.5981 46.1725 32.8713 47.0865Z"
				fill="#111111"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M31.6892 46.5542C33.2565 46.5542 34.527 47.8247 34.527 49.392V75.8785C34.527 77.4458 33.2565 78.7164 31.6892 78.7164C30.1219 78.7164 28.8513 77.4458 28.8513 75.8785V49.392C28.8513 47.8247 30.1219 46.5542 31.6892 46.5542Z"
				fill="#111111"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M42.5676 55.0675C42.5676 49.3208 47.2263 44.6621 52.973 44.6621C58.7198 44.6621 63.3784 49.3208 63.3784 55.0675V68.3108C63.3784 74.0575 58.7198 78.7162 52.973 78.7162C47.2263 78.7162 42.5676 74.0575 42.5676 68.3108V55.0675ZM52.973 50.3378C50.3609 50.3378 48.2433 52.4554 48.2433 55.0675V68.3108C48.2433 70.9229 50.3609 73.0405 52.973 73.0405C55.5852 73.0405 57.7028 70.9229 57.7028 68.3108V55.0675C57.7028 52.4554 55.5852 50.3378 52.973 50.3378Z"
				fill="#111111"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M83.9523 47.0865C85.2255 48.0006 85.5166 49.7737 84.6025 51.0468L74.5849 64.9999H87.5C89.0673 64.9999 90.3378 66.2704 90.3378 67.8377C90.3378 69.405 89.0673 70.6755 87.5 70.6755H69.054C67.9898 70.6755 67.015 70.0801 66.5292 69.1332C66.0433 68.1863 66.1281 67.0472 66.7488 66.1827L79.992 47.7367C80.9061 46.4636 82.6792 46.1725 83.9523 47.0865Z"
				fill="#111111"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M82.7702 46.5542C84.3375 46.5542 85.608 47.8247 85.608 49.392V75.8785C85.608 77.4458 84.3375 78.7164 82.7702 78.7164C81.2029 78.7164 79.9324 77.4458 79.9324 75.8785V49.392C79.9324 47.8247 81.2029 46.5542 82.7702 46.5542Z"
				fill="#111111"
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M0 34.2568C0 32.6895 1.27054 31.4189 2.83784 31.4189H101.216C102.784 31.4189 104.054 32.6895 104.054 34.2568C104.054 35.8241 102.784 37.0946 101.216 37.0946H2.83784C1.27054 37.0946 0 35.8241 0 34.2568Z"
				fill="#111111"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3060_82535">
				<rect width="110" height="110" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
