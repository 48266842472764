/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { components, ControlProps, PropsValue } from 'react-select';

import { Close as X, Search as SearchIcon } from '@ctlyst.id/internal-icon';
import { Box, Flex } from '@ctlyst.id/internal-ui';

import { SelectCreatable } from '@components';

const Control = ({ children, ...props }: ControlProps) => (
	<components.Control {...props}>
		<Flex ml={3} align="center">
			<SearchIcon size={4} color="neutral.500" />
		</Flex>
		{children}
	</components.Control>
);

interface SearchProps {
	onChange?: (data: any) => void;
	value?: string;
	placeholder?: string;
}

const Search = ({ onChange, value: valueProps, placeholder }: SearchProps) => {
	const [value, setValue] = useState<PropsValue<unknown>>();
	const [inputValue, setInputValue] = useState<any>();

	const handleChange = /* istanbul ignore next */ (newValue: any) => {
		const { value: newVal, label: newLabel } = newValue || {};

		if (newVal === '') {
			if (onChange) {
				onChange('');
			}
			setValue('');
			setInputValue('');
		} else {
			if (onChange) {
				onChange(newVal);
			}
			setValue(newValue);
			setInputValue(newLabel);
		}
	};

	const handleInputChange = (values: unknown, { action }: any) => {
		if (action === 'input-change') {
			setInputValue(values);
			setValue(values);
		}
	};

	const formatCreateLabel = (inputVal: string) => `Cari "${inputVal}"`;

	useEffect(() => {
		if (valueProps) {
			setInputValue(valueProps ?? '');
		}
	}, []);

	return (
		<Box
			sx={{
				'.base__input-container': {
					'&:after': { visibility: 'visible!important' },
				},
			}}
		>
			<SelectCreatable
				classNamePrefix="base"
				data-test-id="CT_component_search_select"
				placeholder={placeholder}
				createOptionPosition="first"
				isValidNewOption={() => true}
				formatCreateLabel={formatCreateLabel}
				onChange={handleChange}
				onInputChange={handleInputChange}
				value={value}
				inputValue={inputValue}
				isClearable
				components={{
					DropdownIndicator: () => null,
					IndicatorSeparator: () => null,
					Control,
					ClearIndicator: ({ clearValue }) => (
						<Box
							data-test-id="CT_page_search_close-indicator"
							mr={2}
							pt={1}
							onClick={clearValue}
						>
							<X size={4} color="neutral.900" />
						</Box>
					),
				}}
			/>
		</Box>
	);
};

Search.defaultProps = {
	placeholder: 'Cari',
};

export default Search;
