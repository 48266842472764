import { ReactNode } from 'react';

import Head from 'next/head';

interface AppHeadProps {
	title?: string;
	description?: string;
	children?: ReactNode;
}

const AppHead = ({ title, description, children }: AppHeadProps) => {
	return (
		<Head>
			<title>{title}</title>
			<meta name="description" content={description} />
			{children}
		</Head>
	);
};

AppHead.defaultProps = {
	title: 'CMS - voila.id',
	description: '',
};

export default AppHead;
