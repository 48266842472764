import React from 'react';

import {
	Flex,
	FormControl,
	InputAddonRight as InputRightAddon,
	InputField as Input,
	InputGroup,
	Text,
} from '@ctlyst.id/internal-ui';

interface TimeInputProps {
	value?: string;
	onChange?: (time: string) => void;
	label?: string;
	rightAddon?: React.ReactNode | string;
	testId?: string;
}

const TimeInput: React.FC<TimeInputProps> = ({
	value,
	onChange,
	label,
	rightAddon,
	testId,
}) => {
	const [time, setTime] = React.useState(value || '00:00');

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTime(e.target?.value || '00:00');
	};

	React.useEffect(() => {
		if (onChange) {
			onChange(time);
		}
	}, [time, onChange]);

	return (
		<FormControl isInvalid={false}>
			<Flex flexDir="column" w="full" p={4} pt={0}>
				<Text mb={2} fontSize="xs">
					{label}
				</Text>
				<InputGroup>
					<Input
						minW="75%"
						onChange={handleChange}
						type="time"
						value={time}
						data-test-id={`CT_component_time-input_input${
							testId ? `_${testId}` : ''
						}`}
					/>
					{rightAddon && <InputRightAddon>{rightAddon}</InputRightAddon>}
				</InputGroup>
			</Flex>
		</FormControl>
	);
};

TimeInput.displayName = 'DatepickerTimeInput';

TimeInput.defaultProps = {
	value: '00:00',
	onChange: undefined,
	label: 'Pilih Waktu',
	rightAddon: 'WIB',
};
export default TimeInput;
