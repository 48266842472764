import type { Ref } from 'react';
import { forwardRef } from 'react';
import type { CSSObjectWithLabel, GroupBase, Props } from 'react-select';
import ReactSelect, { components as RSComponent } from 'react-select';

import { Box, Center, useColorMode } from '@ctlyst.id/internal-ui';

import { selectStyles, themeSelect } from './utils';

const _Select = <Option,>(
	{ isError = false, ...props }: Props<Option> & { isError?: boolean },
	ref: Ref<HTMLDivElement>
) => {
	const { colorMode } = useColorMode();
	const { components, styles, ...restProps } = props;
	return (
		<Box ref={ref}>
			<ReactSelect
				components={{
					Control: ({ innerRef, ...controlProps }) => (
						<RSComponent.Control innerRef={innerRef} {...controlProps} />
					),
					NoOptionsMessage: noOptionProps => {
						if (noOptionProps.options.length === 0) {
							return (
								<Center py="2" color={'black.medium'}>
									Pilihan tidak tersedia
								</Center>
							);
						}
						return (
							<Center py="2" color={'black.medium'}>
								Keyword tidak ditemukan
							</Center>
						);
					},
					...components,
				}}
				{...restProps}
				styles={{
					valueContainer: (_styles: CSSObjectWithLabel) => {
						return {
							..._styles,
							padding: '8px 8px',
						};
					},
					menuPortal: base => ({ ...base, zIndex: 9999 }),
					...selectStyles(colorMode, isError),
					...styles,
				}}
				theme={themeSelect}
			/>
		</Box>
	);
};
const Select = forwardRef(_Select) as <
	Option,
	IsMulti extends boolean,
	Group extends GroupBase<Option>
>({
	isError = false,
	...props
}: Props<Option, IsMulti, Group> & {
	isError?: boolean;
	ref?: Ref<HTMLDivElement>;
}) => ReturnType<typeof _Select>;

export default Select;
