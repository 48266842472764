/* eslint-disable react/display-name */
import React, {
	forwardRef,
	MutableRefObject,
	ReactElement,
	RefAttributes,
} from 'react';
import { GroupBase, Props, SelectInstance } from 'react-select';
import ReactSelectAsyncCreatable, {
	AsyncCreatableProps,
} from 'react-select/async-creatable';

import { useColorMode } from '@ctlyst.id/internal-ui';

import { selectStyles, themeSelect } from './utils';

export type SelectAsyncCreatableComponent = <
	OptionType = unknown,
	IsMulti extends boolean = false,
	Group extends GroupBase<OptionType> = GroupBase<OptionType>
>(
	props: AsyncCreatableProps<OptionType, IsMulti, Group> &
		RefAttributes<SelectInstance<OptionType, IsMulti, Group>>
) => ReactElement;

const SelectAsyncCreatable = forwardRef(
	<OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>>(
		{ styles, ...props }: Props<OptionType, IsMulti, Group>,
		ref:
			| ((instance: SelectInstance<OptionType, IsMulti, Group> | null) => void)
			| MutableRefObject<SelectInstance<OptionType, IsMulti, Group> | null>
			| null
	) => {
		const { colorMode } = useColorMode();
		return (
			<ReactSelectAsyncCreatable
				ref={ref}
				{...props}
				styles={{ ...selectStyles(colorMode), ...styles }}
				theme={themeSelect}
			/>
		);
	}
) as SelectAsyncCreatableComponent;

export default SelectAsyncCreatable;
