import { ReactElement, ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';

import type { NextPage } from 'next';
import App, { AppContext, AppProps } from 'next/app';

import { ChakraProvider, getTheme } from '@ctlyst.id/internal-ui';
import voilaTheme from '@ctlyst.id/voila-theme';
import { PrivateRoute, PublicRoute } from '@modules/auth';
import { AuthProvider } from '@modules/auth/auth-provider';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '@utils/query-client';

import '@services/client';

import { Layout } from '@containers';

import '@components/device-preview/devices-preview.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500-italic.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600-italic.css';
import '@fontsource/poppins/600.css';
import '@fontsource/playfair-display/400.css';
import '@fontsource/playfair-display/400-italic.css';
import '@fontsource/playfair-display/500.css';
import '@fontsource/playfair-display/500-italic.css';
import '@fontsource/playfair-display/600.css';
import '@fontsource/playfair-display/600-italic.css';
import '@fontsource/playfair-display/700.css';
import '@fontsource/playfair-display/700-italic.css';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import '@styles/react-toast.css';
import '@styles/quil.css';
import '@styles/suneditor.css';

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
	publicRoute?: (page: ReactElement) => ReactNode;
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
	Component: NextPageWithLayout;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
	const getLayout = Component.getLayout ?? (page => <Layout>{page}</Layout>);
	const Route = Component.publicRoute ? PublicRoute : PrivateRoute;

	const theme = getTheme(voilaTheme);

	return (
		<QueryClientProvider client={queryClient}>
			<ChakraProvider theme={theme}>
				<AuthProvider>
					{getLayout(
						<Route>
							<Component {...pageProps} />
						</Route>
					)}
				</AuthProvider>
				<ToastContainer
					className="toastify-custom"
					toastClassName="toastify-fonts"
					icon={false}
					theme="colored"
					pauseOnFocusLoss={false}
				/>
			</ChakraProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	);
};

MyApp.getInitialProps = async (appContext: AppContext) => {
	// calls page's `getInitialProps` and fills `appProps.pageProps`
	const appProps = await App.getInitialProps(appContext);
	return { ...appProps };
};

export default MyApp;
