import { useEffect, useMemo, useRef, useState } from 'react';
import { InnerImageZoom } from 'react-inner-image-zoom';
import Slider, { Settings } from 'react-slick';

import { ChevronLeft, ChevronRight } from '@ctlyst.id/internal-icon';
import { Box, Image } from '@ctlyst.id/internal-ui';

import { Modal } from '@components/modal';

interface PopupImageProps {
	isOpen: boolean;
	onClose: () => void;
	images: string[];
	initialSlide?: number;
	title?: string;
	testId?: string;
}

const customStyle = {
	'.slick-arrow': {
		'&:before': {
			display: 'none',
		},
		'&:hover': {
			color: 'primary.500',
		},
	},
	'.slick-track': {
		display: 'flex',
		'.slick-slide': {
			height: 'auto',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	},
	'.slick-slide': {
		margin: '0 4px',
	},
};

const PopupImage = ({
	isOpen,
	onClose,
	images,
	initialSlide = 0,
	title = 'Lihat Gambar',
	testId,
}: PopupImageProps) => {
	const newInitialSlider = useMemo(
		() =>
			initialSlide > images.length - 1 || initialSlide < 0 ? 0 : initialSlide,
		[initialSlide, images]
	);
	const [activeSlide, setActiveSlide] = useState(newInitialSlider);

	const sliderRef = useRef<Slider>(null);

	const setting: Settings = {
		dots: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		initialSlide: newInitialSlider,
		speed: 300,
		prevArrow: (
			<Box color="primary.500" data-test-id="CT_component_popup-image_previous">
				<ChevronLeft />
			</Box>
		),
		nextArrow: (
			<Box color="primary.500" data-test-id="CT_component_popup-image_next">
				<ChevronRight />
			</Box>
		),
		beforeChange(_, nextSlide) {
			setActiveSlide(nextSlide);
		},
	};

	function handleClick(position: number) {
		sliderRef.current?.slickGoTo(position);
	}

	useEffect(() => {
		setActiveSlide(initialSlide);
	}, [initialSlide]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				header={title}
				onClose={onClose}
				contentStyles={{ w: 700 }}
				isCentered
				testId="preview_image"
			>
				<Box
					pl={10}
					pr={10}
					sx={customStyle}
					data-test-id={`CT_component_popup-image_modal${
						testId ? `_${testId}` : ''
					}`}
				>
					{images.length <= 0 && (
						<Box
							w="full"
							height={400}
							textAlign="center"
							display="flex"
							justifyContent="center"
							alignItems="center"
							fontSize={40}
						>
							No Image !
						</Box>
					)}
					<Slider {...setting} ref={sliderRef}>
						{images.map(val => (
							<Box key={val}>
								<InnerImageZoom
									imgAttributes={{
										alt: `image-${val}`,
										id: `image-id-${val}`,
									}}
									src={val}
									zoomType="hover"
									zoomPreload={true}
									hideHint
								/>
							</Box>
						))}
					</Slider>
					<Box
						display={images.length > 1 ? 'flex' : 'none'}
						justifyContent="center"
						alignItems="center"
						mt={1}
						w="100%"
						overflow="auto"
						gap={1}
						sx={{
							'&::-webkit-scrollbar': {
								display: 'none',
							},
						}}
					>
						{images.map((val, key) => (
							<Image
								data-test-id={`CT_component_popup-image-list-${key}${
									testId ? `_${testId}` : ''
								}`}
								alt=""
								borderRadius={10}
								src={val}
								key={val}
								w={13}
								h={13}
								objectFit="cover"
								cursor="pointer"
								border={`2px solid`}
								borderColor={key === activeSlide ? 'primary.500' : 'white'}
								onClick={() => handleClick(key)}
							/>
						))}
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default PopupImage;
