/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ACCESS_TOKEN } from '@modules/auth/constants';
import { onLogout } from '@modules/auth/utils/helpers';
import getRuntimeEnv from '@utils/env';
import { isBrowser } from '@utils/ssr';
import Axios, { AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import { getCookie } from 'cookies-next';
import qs from 'query-string';

const API_CMS_URL = getRuntimeEnv('API_URL', '');

type SourceRequest = {
	[key: string]: Canceler;
};
const sourceRequest: { [key: string]: SourceRequest } = {};
const CancelToken = Axios.CancelToken;

Axios.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		const accessToken: any = getCookie(ACCESS_TOKEN) ?? null;
		// const isExternal = !!config?.url?.startsWith(`https`);
		const token = isBrowser ? accessToken : null;
		const newHeaders = {
			...config.headers,
		};

		if (token) {
			newHeaders.ContentType = 'application/json';
			newHeaders.Authorization = `Bearer ${token}`;
		}

		// Handle cancel request for feature flag only
		if (config.url?.includes('/api/features/sdk')) {
			const urlString = config.url as string;

			// If the axios cancel exists cancel
			if (sourceRequest[urlString]) {
				sourceRequest[urlString].cancel();
			}

			// Store or update axios cancel token
			config.cancelToken = new CancelToken(e => {
				sourceRequest[urlString] = {
					cancel: e,
				};
			});
		}

		return {
			...config,
			baseURL: API_CMS_URL || '/api',
			headers: { ...newHeaders, 'Access-Control-Allow-Origin': '*' },
			paramsSerializer: params => qs.stringify(params),
		};
	},
	error => Promise.reject(error)
);

Axios.interceptors.response.use(
	(response: AxiosResponse) => {
		return response.data;
	},
	error => {
		const originalRequest = error.config;

		if (
			error.response?.status === 401 &&
			error.config.url === `/auth/refresh`
		) {
			onLogout();
			return Promise.reject(error);
		} else if (
			error.response?.status === 401 &&
			!originalRequest._retry &&
			error.config.url !== `/auth/login`
		) {
			originalRequest._retry = true;
		}

		return Promise.reject(error);
	}
);

export async function requestFn<T>({
	url,
	...etc
}: AxiosRequestConfig): Promise<T> {
	return Axios.request({ url: String(url), ...etc });
}
