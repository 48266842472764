import * as React from 'react';

import { SVGIconProps } from './types';

const LongAnswerIcon = ({ size = 24, ...rest }: SVGIconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.33203 6.66667C1.33203 6.29848 1.63051 6 1.9987 6H13.9987C14.3669 6 14.6654 6.29848 14.6654 6.66667C14.6654 7.03486 14.3669 7.33333 13.9987 7.33333H1.9987C1.63051 7.33333 1.33203 7.03486 1.33203 6.66667Z"
			fill="#111111"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.33203 3.99992C1.33203 3.63173 1.63051 3.33325 1.9987 3.33325H13.9987C14.3669 3.33325 14.6654 3.63173 14.6654 3.99992C14.6654 4.36811 14.3669 4.66659 13.9987 4.66659H1.9987C1.63051 4.66659 1.33203 4.36811 1.33203 3.99992Z"
			fill="#111111"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.33203 9.33341C1.33203 8.96522 1.63051 8.66675 1.9987 8.66675H13.9987C14.3669 8.66675 14.6654 8.96522 14.6654 9.33341C14.6654 9.7016 14.3669 10.0001 13.9987 10.0001H1.9987C1.63051 10.0001 1.33203 9.7016 1.33203 9.33341Z"
			fill="#111111"
		/>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M1.33203 11.9999C1.33203 11.6317 1.63051 11.3333 1.9987 11.3333H9.9987C10.3669 11.3333 10.6654 11.6317 10.6654 11.9999C10.6654 12.3681 10.3669 12.6666 9.9987 12.6666H1.9987C1.63051 12.6666 1.33203 12.3681 1.33203 11.9999Z"
			fill="#111111"
		/>
	</svg>
);
export default LongAnswerIcon;
