import { useRouter } from 'next/router';

export interface PageParams {
	keyword?: string | string[] | undefined;
	status?: string | string[] | undefined;
	sort_by?: string | string[] | undefined;
	sort_order?: string | string[] | undefined;
	page?: number;
	size?: number;
}
// cast pagination params type
export const usePaginateParam = <T extends PageParams>(
	{
		withRest,
	}: {
		withRest: boolean;
	} = { withRest: true }
) => {
	const { query } = useRouter();

	const { page, size, ...rest } = query;

	const params = {
		page: page ? Number(page) : 1,
		size: size ? Number(size) : 10,
		...(withRest ? rest : {}),
	};
	return params as T;
};
