export const handleScroll = (id: string) => {
	const element = document.getElementById(id);

	if (element) {
		window.scrollTo({
			behavior: 'smooth',
			top:
				element.getBoundingClientRect().top -
				document.body.getBoundingClientRect().top -
				20,
		});
	}
};
