import Axios from 'axios';

import {
	MenuNavigationLogResponse,
	MenuNavigationLogsParams,
} from './menu-log.types';

export const getMenuNavigationLog = (params: MenuNavigationLogsParams) => {
	return Axios.get<MenuNavigationLogResponse>(
		`/menu_navigations/${params.id}/logs?page=${params.page || 1}`
	);
};
