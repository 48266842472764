import { isRatioEqual } from '@utils';

export const FILE_SIZE = {
	'2MB': { value: 2_000_000, label: '2MB' },
};
export const messages = {
	dragActive: 'Drop file here...',
	dragInActive: `(Click or drag to input file)`,
	dragReplace: `(Click or drag to change file)`,
	replaceFile: 'Replace File',
	uploadFile: 'Upload File',
	changeFile: 'Change File',
};

export const IMG_EXT = {
	jpg: 'jpg',
	jpeg: 'jpeg',
	png: 'png',
	gif: 'gif',
} as const;

export const DEFAULT_IMAGE_MIME_TYPE = [
	IMG_EXT.jpg,
	IMG_EXT.jpeg,
	IMG_EXT.png,
	IMG_EXT.gif,
];

/**
 * concatList, join string and add `dan` at the end
 */
export const concatList = (list: string[]) => {
	let _concatedList = '';
	if (list.length > 1) {
		_concatedList =
			list.slice(0, -1).join(', ') + ' dan ' + list[list.length - 1];
	} else {
		_concatedList = list[0];
	}
	return _concatedList;
};

export const getHelperText = (
	extension: string[],
	dimension: { w: number; h: number },
	size: string
) => {
	const { w, h } = dimension;
	const extensionString = concatList(extension.map(ext => `.${ext}`));
	return [
		`Format foto ${extensionString}.`,
		`Dimensi foto minimal kelipatan ${w} x ${h} px.`,
		`Ukuran foto maksimal ${size}.`,
	];
};

export const formatValidationMessage = (extension: string[]) =>
	`Foto harus dalam format ${concatList(extension.map(ext => `.${ext}`))}.`;
export const dimensionValidationMessage = (
	ratioWidth: number,
	ratioHeight: number
) =>
	`Foto tidak sesuai dengan minimal dimensi ${ratioWidth} x ${ratioHeight} px.`;
export const sizeValidationMessage = (size: string) =>
	`Ukuran foto melebihi ${size}.`;

export const ratioAndSizeValidationMessage = ({
	image,
	ratio,
	fileSize,
	maxFileSize,
}: {
	image: {
		w: number;
		h: number;
	};
	ratio: {
		rw: number;
		rh: number;
	};
	fileSize: number;
	maxFileSize: {
		value: number;
		label: string;
	};
}) => {
	const isRatioValid = isRatioEqual(
		{ width: ratio.rw, height: ratio.rh },
		{ width: image.w, height: image.h }
	);

	const isSizeValid = fileSize <= maxFileSize.value;

	if (!isRatioValid && !isSizeValid) {
		return `Dimensi foto minimal ${ratio.rw} x ${ratio.rh} px dan ukuran maksimal ${maxFileSize.label}.`;
	}

	if (!isRatioValid) {
		return `Foto tidak sesuai dengan minimal dimensi ${ratio.rw} x ${ratio.rh} px.`;
	}

	return null;
};
