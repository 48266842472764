import * as React from 'react';

import { SVGIconProps } from './types';

export const FolderIcon = ({ size = 24, ...rest }: SVGIconProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		data-test-id="CT_component_folder-icon_cms"
		width={size}
		height={size}
		viewBox="0 0 110 110"
		fill="none"
		{...rest}
	>
		<path
			fill="#E0E0E0"
			fillRule="evenodd"
			d="M10 26.5c0-7.18 5.82-13 13-13h16.027a13 13 0 0 1 10.95 5.992l3.87 6.047a1 1 0 0 0 .842.461H91c7.18 0 13 5.82 13 13v45.5c0 7.18-5.82 13-13 13H23c-7.18 0-13-5.82-13-13v-58Zm13-7a7 7 0 0 0-7 7v58a7 7 0 0 0 7 7h68a7 7 0 0 0 7-7V39a7 7 0 0 0-7-7H54.69a7 7 0 0 1-5.897-3.227l-3.87-6.046a7 7 0 0 0-5.896-3.227H23Z"
			clipRule="evenodd"
		/>
		<path
			fill="#E0E0E0"
			fillRule="evenodd"
			d="M10 26.5c0-7.18 5.82-13 13-13h16.027a13 13 0 0 1 10.95 5.992l3.87 6.047a1 1 0 0 0 .842.461H91c7.18 0 13 5.82 13 13v45.5c0 7.18-5.82 13-13 13H23c-7.18 0-13-5.82-13-13v-58Zm13-7a7 7 0 0 0-7 7v58a7 7 0 0 0 7 7h68a7 7 0 0 0 7-7V39a7 7 0 0 0-7-7H54.69a7 7 0 0 1-5.897-3.227l-3.87-6.046a7 7 0 0 0-5.896-3.227H23Z"
			clipRule="evenodd"
		/>
		<path
			fill="#111"
			fillRule="evenodd"
			d="M6 26.5c0-7.18 5.82-13 13-13h16.027a13 13 0 0 1 10.95 5.992l3.87 6.047a1 1 0 0 0 .842.461H87c7.18 0 13 5.82 13 13v45.5c0 7.18-5.82 13-13 13H19c-7.18 0-13-5.82-13-13v-58Zm13-7a7 7 0 0 0-7 7v58a7 7 0 0 0 7 7h68a7 7 0 0 0 7-7V39a7 7 0 0 0-7-7H50.69a7 7 0 0 1-5.897-3.227l-3.87-6.046a7 7 0 0 0-5.896-3.227H19Z"
			clipRule="evenodd"
		/>
		<path
			fill="#111"
			fillRule="evenodd"
			d="M6 26.5c0-7.18 5.82-13 13-13h16.027a13 13 0 0 1 10.95 5.992l3.87 6.047a1 1 0 0 0 .842.461H87c7.18 0 13 5.82 13 13v45.5c0 7.18-5.82 13-13 13H19c-7.18 0-13-5.82-13-13v-58Zm13-7a7 7 0 0 0-7 7v58a7 7 0 0 0 7 7h68a7 7 0 0 0 7-7V39a7 7 0 0 0-7-7H50.69a7 7 0 0 1-5.897-3.227l-3.87-6.046a7 7 0 0 0-5.896-3.227H19Z"
			clipRule="evenodd"
		/>
	</svg>
);
