import React, { FC, PropsWithChildren } from 'react';

import {
	Box,
	BreadCrumb,
	BreadCrumbProps as IdsBreadCrumbProps,
	Flex,
} from '@ctlyst.id/internal-ui';

interface BreadCrumbProps extends IdsBreadCrumbProps {
	testId?: string;
}

const PanelBar: FC<PropsWithChildren<BreadCrumbProps>> = props => {
	const {
		title = 'Ini Title',
		parents,
		className,
		disableHome,
		testId,
		children,
	} = props;

	return (
		<Flex
			className={className}
			h={9}
			alignItems="center"
			justifyContent="space-between"
			my={4}
			data-test-id={`CT_component_panel-bar_cms${testId ? `_${testId}` : ''}`}
		>
			<BreadCrumb title={title} disableHome={disableHome} parents={parents} />

			{children && <Box>{children}</Box>}
		</Flex>
	);
};

export default PanelBar;
