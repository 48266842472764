import { CSSProperties } from 'react';
import {
	Draggable,
	DraggingStyle,
	NotDraggingStyle,
} from 'react-beautiful-dnd';

import { Close } from '@ctlyst.id/internal-icon';
import {
	Center,
	Flex,
	IconButton,
	Image,
	Loader as Spinner,
	Text,
} from '@ctlyst.id/internal-ui';

interface PreviewImageProps {
	src: string;
	index: number;
	isLoading?: boolean;
	onPreview: (src: string) => void;
	onRemove: (index: number) => void;
}

const getItemStyle = (
	isDragging: boolean,
	draggableStyle?: DraggingStyle | NotDraggingStyle | CSSProperties
) => {
	return {
		boxShadow: isDragging ? '0px 10px 15px -3px rgba(0,0,0,0.1)' : 'none',
		cursor: 'pointer',
		margin: `0 14px 0 0`,
		...draggableStyle,
	};
};

const PreviewImage = ({
	src,
	index,
	onRemove,
	onPreview,
}: PreviewImageProps) => {
	const isLoading = src?.includes('blob');

	return (
		<Draggable
			index={index}
			key={src}
			draggableId={`${String(src)}-${index}`}
			isDragDisabled={isLoading}
		>
			{(draggableProvided, draggableSnapshot) => (
				<Flex
					ref={draggableProvided.innerRef}
					{...draggableProvided.draggableProps}
					{...draggableProvided.dragHandleProps}
					data-test-id="CT_component_preview-image_multiple"
					align="center"
					justify="center"
					h="120px"
					w="120px"
					userSelect="none"
					border="1px"
					borderColor="neutral.400"
					rounded="md"
					cursor="pointer"
					color="primary.500"
					bg="white"
					pos="relative"
					style={getItemStyle(
						draggableSnapshot.isDragging,
						draggableProvided.draggableProps.style
					)}
					onClick={() => onPreview(src)}
					_hover={{
						'.overlay': {
							display: 'flex',
						},
					}}
				>
					<Center
						className="overlay"
						rounded="md"
						bg="rgba(47, 47, 47, 0.576)"
						h="full"
						textAlign="center"
						w="full"
						pos="absolute"
						top="0"
						left="0"
						right="0"
						bottom="0"
						display={isLoading ? 'flex' : 'none'}
						zIndex={2}
						color="white"
					>
						{isLoading ? (
							<Spinner color="neutral.100" size="md" />
						) : (
							<Text opacity="1" textStyle="text.xs">
								Geser foto untuk mengubah urutan
							</Text>
						)}
					</Center>
					<IconButton
						aria-label="upload"
						data-test-id=""
						pos="absolute"
						isRound
						variant="solid"
						border="1px"
						borderColor="neutral.200"
						colorScheme="neutral.200"
						top="4px"
						right="4px"
						bg="white"
						zIndex={3}
						onClick={e => {
							e.stopPropagation();
							onRemove(index);
						}}
						minW="21px"
						h="21px"
						icon={<Close color="black" size={4} />}
					/>
					<Image alt="" src={src} />
				</Flex>
			)}
		</Draggable>
	);
};

export default PreviewImage;
