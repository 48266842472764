import React, {
	Children,
	createContext,
	forwardRef,
	ReactNode,
	useContext,
	useState,
} from 'react';

import { ChevronDown, ChevronUp } from '@ctlyst.id/internal-icon';
import {
	Box,
	BoxProps,
	Collapse,
	Flex,
	FlexProps,
} from '@ctlyst.id/internal-ui';

interface AccordionContextDefault {
	value: string | string[];
	setValue: (val: string | string[]) => void;
}

export const AccordionContext = createContext<AccordionContextDefault>(
	{} as AccordionContextDefault
);

export const Accordion = ({
	children,
	defaultValue,
}: {
	defaultValue?: string | string[];
	children: ReactNode;
}) => {
	const getDefaultValue = () => {
		if (Array.isArray(defaultValue)) {
			return defaultValue;
		}
		if (defaultValue) {
			return [defaultValue];
		}
		return '';
	};
	const [value, setValue] = useState<string | string[]>(getDefaultValue());

	return (
		<AccordionContext.Provider value={{ value, setValue }}>
			{children}
		</AccordionContext.Provider>
	);
};

interface AccordionItemContextDefault {
	itemValue: string;
}

const AccordionItemContext = createContext<AccordionItemContextDefault>(
	{} as AccordionItemContextDefault
);

export const AccordionItem = ({
	children,
	itemValue,
}: {
	children: ReactNode;
	itemValue: string;
}) => {
	return (
		<AccordionItemContext.Provider value={{ itemValue }}>
			{children}
		</AccordionItemContext.Provider>
	);
};

interface AccordionHeaderProps {
	children: ReactNode;
}

export const AccordionHeader = forwardRef<
	HTMLDivElement,
	AccordionHeaderProps | FlexProps
>(({ children, ...props }, ref) => {
	const contextProvider = useContext(AccordionContext);
	const contextItem = useContext(AccordionItemContext);

	const { value, setValue } = contextProvider;
	const { itemValue } = contextItem;
	const isMultiple = Array.isArray(value);

	const triggerAccordion = () => {
		if (isMultiple) {
			const newValue = [...value];
			const findIndex = newValue.findIndex(val => val === itemValue);
			if (findIndex > -1) {
				newValue.splice(findIndex, 1);
			} else {
				newValue.push(itemValue);
			}
			setValue(newValue);
			return;
		}
		setValue(value === itemValue ? '' : itemValue);
	};

	const arrayChildren = Children.toArray(children);

	const isSelected = (val: string) => {
		if (isMultiple) {
			return value.includes(val);
		}
		return value === val;
	};

	return (
		<Flex
			borderTop="1px solid"
			borderColor="neutral.300"
			data-test-id=""
			gap={2}
			pr={4}
			cursor="pointer"
			align="center"
			userSelect="none"
			ref={ref}
			{...props}
		>
			<Flex
				py={3}
				px={4}
				align="center"
				w="full"
				data-test-id=""
				gap={4}
				onClick={triggerAccordion}
			>
				{isSelected(itemValue) ? (
					<ChevronUp size={4} />
				) : (
					<ChevronDown size={4} />
				)}
				<Box>{arrayChildren[0]}</Box>
			</Flex>
			{arrayChildren[1]}
		</Flex>
	);
});

AccordionHeader.displayName = 'AccordionHeader';

interface AccordionContentProps {
	children: ReactNode;
	boxProps?: BoxProps;
	style?: React.CSSProperties;
}

export const AccordionContent: React.FC<AccordionContentProps> = ({
	children,
	boxProps = {},
	...props
}) => {
	const contextProvider = useContext(AccordionContext);
	const contextItem = useContext(AccordionItemContext);

	const { value } = contextProvider;
	const { itemValue } = contextItem;
	const isMultiple = Array.isArray(value);

	const isSelected = (val: string) => {
		if (isMultiple) {
			return value.includes(val);
		}
		return value === val;
	};

	return (
		<Collapse in={isSelected(itemValue)} animateOpacity {...props}>
			<Box
				pl={12}
				bg="neutral.100"
				borderTop="1px solid"
				borderColor="neutral.300"
				{...boxProps}
			>
				{children}
			</Box>
		</Collapse>
	);
};

interface AccordionActionProps {
	children: ReactNode;
}

export const AccordionAction = ({
	children,
	...props
}: AccordionActionProps | FlexProps) => (
	<Flex align="center" gap={2} {...props}>
		{children}
	</Flex>
);
