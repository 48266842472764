import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '@utils';
import { ErrorData, ResponseData } from 'axios';

import {
	getMenu,
	getMenuNavigation,
	getMenuNavigationById,
	updateMenuNavigationById,
} from './menu.service';
import {
	Menu,
	MenuNavigation,
	MenuNavigationDetail,
	MenuNavigationDetailPayload,
	MenuNavigationDetailResponse,
	MenuNavigationResponse,
	MenuResponse,
} from './menu.types';

// header menu cms
export const useGetMenuQuery = () => {
	const queryInfo = useQuery<MenuResponse, ErrorData, Menu[]>(
		['menu'],
		() => getMenu(),
		{
			select: data => data.data,
		}
	);

	return {
		...queryInfo,
		data: queryInfo.data,
	};
};

// menu navigations cms module
export const useGetMenuNavigationQuery = () => {
	const queryInfo = useQuery<
		MenuNavigationResponse,
		ErrorData,
		MenuNavigation[]
	>(['menu-navigation'], () => getMenuNavigation(), {
		select: data => data.data,
	});

	return {
		...queryInfo,
		data: queryInfo.data,
	};
};

export const useGetMenuNavigationDetailQuery = (id: number) => {
	const queryInfo = useQuery<
		MenuNavigationDetailResponse,
		ErrorData,
		MenuNavigationDetail
	>(['menu-navigation', id], () => getMenuNavigationById(id), {
		select: data => data.data,
		enabled: !!id,
		onSuccess: () => {
			queryClient.invalidateQueries(['menu-navigation-log']);
		},
	});

	return queryInfo;
};

export const useMenuNavigationDetailMutation = () => {
	return useMutation<
		ResponseData<null>,
		ErrorData,
		{ id: number; data: MenuNavigationDetailPayload }
	>(async ({ id, data }) => {
		return updateMenuNavigationById(id, data);
	});
};
