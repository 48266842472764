/* eslint-disable import/prefer-default-export */
import { CSSObjectWithLabel, Theme } from 'react-select';

import { ColorMode } from '@ctlyst.id/internal-ui';
import foundations from '@ctlyst.id/voila-theme';

const colors = foundations.colors || {}; // Get color foundation from ids

export const selectStyles = (colorMode?: ColorMode, isError?: boolean) => {
	return {
		control: (base: CSSObjectWithLabel) =>
			colorMode === 'dark'
				? {
						...base,
						background: colors.neutral['800'],
						color: colors.primary['300'],
						boxShadow: 'none',
						...(isError ? { borderColor: colors.danger[500] } : {}),
				  }
				: {
						...base,
						fontSize: 12,
						boxShadow: 'none',
						borderColor: 'none',
						...(isError ? { borderColor: colors.danger[500] } : {}),
				  },
		option: (
			base: CSSObjectWithLabel,
			{ isSelected }: { isSelected: boolean }
		) =>
			colorMode === 'dark'
				? {
						...base,
						fontSize: 12,
						background: isSelected
							? colors.primary['700']
							: colors.neutral['800'],
						color: 'white',
				  }
				: { ...base, fontSize: 12 },
		menu: (base: CSSObjectWithLabel) =>
			colorMode === 'dark'
				? {
						...base,
						fontSize: 12,
						background: colors.neutral['800'],
				  }
				: { ...base, fontSize: 12 },
		singleValue: (base: CSSObjectWithLabel) =>
			colorMode === 'dark' ? { ...base, color: 'white' } : { ...base },
		input: (base: CSSObjectWithLabel) =>
			colorMode === 'dark'
				? {
						...base,
						margin: 0,
						padding: 0,
						color: 'white',
						borderWidth: 1,
						lineHeight: '18px',
				  }
				: { ...base, margin: 0, padding: 0, lineHeight: '18px' },
		dropdownIndicator: (base: CSSObjectWithLabel) =>
			colorMode === 'dark' ? { ...base, color: 'white' } : { ...base },
		placeholder: (base: CSSObjectWithLabel) => ({
			...base,
			lineHeight: '18px',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			color: colors.black.low,
		}),
	};
};

export const themeSelect = (theme: Theme): Theme => {
	return {
		...theme,
		borderRadius: 6,
		colors: {
			...theme.colors,
			primary: colors.primary['500'],
			primary25: colors.primary['50'],
			primary50: colors.primary['100'],
			primary75: colors.primary['200'],
		},
	};
};
