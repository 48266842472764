import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';

import BaseImageUploader, {
	BaseImageUploaderProps,
} from './base-image-uploader';

type ImageUploaderProps<T extends FieldValues> =
	Partial<BaseImageUploaderProps> & {
		control: Control<T, any>;
		name: Path<T>;
	};

const ImageUploader = <T extends FieldValues>({
	name,
	control,
	...props
}: ImageUploaderProps<T>) => {
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<BaseImageUploader
					onHandleUploadFile={file => {
						field.onChange(file);
					}}
					uploadFileText="Pilih File"
					dragInActiveText="(Tarik file atau klik di sini untuk upload)"
					changeFileText="Ubah File"
					value={field.value}
					{...props}
				/>
			)}
		/>
	);
};

export default ImageUploader;
