/* eslint-disable test-selectors/onChange */
import React, { useState } from 'react';
import ReactDatePicker, {
	ReactDatePickerProps,
	registerLocale,
} from 'react-datepicker';

import { dateFormat2 } from '@constants/date-format';
import { Calendar, Close as X } from '@ctlyst.id/internal-icon';
import {
	Box,
	Field,
	FieldProps,
	forwardRef,
	IconButton,
	InputField as Input,
	InputGroup,
	InputLeftElement,
	InputProps,
	InputRightElement,
	Text,
} from '@ctlyst.id/internal-ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import classnames from 'classnames';
import { format } from 'date-fns';
import { id as idn } from 'date-fns/locale';

import Styles from './styles';
import TimeInput from './time-input';

registerLocale('id', idn);

export type DatepickerProps = Omit<
	ReactDatePickerProps,
	'selectsRange' | 'onChange'
> &
	FieldProps & {
		id?: string;
		withTime?: boolean;
		onClear?: () => void;
		selectsRange?: boolean;
		onChange?: (data: string) => void;
		selectedDate?: Date;
		testId?: string;
	};

const InputWithWIB = forwardRef<InputProps, 'input'>((props, ref) => (
	<Box position={'relative'}>
		<Input
			pl={12}
			autoComplete="off"
			ref={ref}
			{...props}
			color={'transparent'}
		/>
		<Text
			as="span"
			position={'absolute'}
			top={'10px'}
			left={'49px'}
			height="auto"
			pointerEvents="none"
		>
			{props.value ? `${props.value} WIB` : ''}
		</Text>
	</Box>
));

const Datepicker: React.FC<DatepickerProps> = ({
	id,
	withTime,
	value,
	name,
	onClear,
	onChange,
	selectedDate,
	testId,
	...props
}) => {
	const selected = selectedDate ? new Date(selectedDate) : undefined;
	const dateFormat = props.dateFormat ?? withTime ? dateFormat2 : 'yyyy-MM-dd';
	const getTimeProps = (): Pick<
		ReactDatePickerProps,
		'showTimeInput' | 'customTimeInput' | 'timeInputLabel'
	> => {
		if (!withTime) return {};
		return {
			timeInputLabel: '',
			showTimeInput: true,
			customTimeInput: <TimeInput testId={testId} />,
		};
	};

	const [date, setDate] = useState('');

	const handleDateInput = (val: Date) => {
		const input = format(val, dateFormat);
		setDate(input);
	};

	const handleOnCalendarClose = () => {
		onChange?.(date);
	};

	const component = (
		<>
			<Styles />
			<ReactDatePicker
				id={id}
				locale="id"
				name={name}
				selected={selected}
				customInput={
					<InputWithWIB
						data-test-id={`CT_component_datepicker${
							testId ? `_${testId}` : ''
						}`}
					/>
				}
				dateFormat={dateFormat}
				showPopperArrow={false}
				calendarClassName={classnames({ inline: props.inline })}
				onChange={handleDateInput}
				onSelect={handleDateInput}
				onCalendarClose={handleOnCalendarClose}
				value={value}
				{...getTimeProps()}
				{...props}
			/>
		</>
	);

	if (props.inline) {
		return component;
	}

	return (
		<Field
			data-test-id={`CT_component_datepicker_field${
				testId ? `_${testId}` : ''
			}`}
			{...props}
		>
			<InputGroup>
				<InputLeftElement zIndex={0} borderRight="1px solid #d8d6de">
					<Calendar color="#82868B" size={15} />
				</InputLeftElement>
				{component}
				<InputRightElement zIndex={0}>
					{value && (
						<IconButton
							aria-label="datepicker_clear-button"
							data-test-id={`CT_component_datepicker_clear-button${
								testId ? `_${testId}` : ''
							}`}
							onClick={onClear}
							icon={<X color="#82868B" size={15} />}
						/>
					)}
				</InputRightElement>
			</InputGroup>
		</Field>
	);
};

Datepicker.defaultProps = {
	id: undefined,
	isRequired: undefined,
	label: undefined,
	errorMessage: undefined,
	isError: undefined,
	withTime: undefined,
	onClear: undefined,
	selectsRange: undefined,
};

export default Datepicker;
