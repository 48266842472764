// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from 'react';

const DeviceList = [
	'iphonex',
	'galaxynote8',
	'iphone8',
	'iphone8plus',
	'iphone5s',
	'iphone5c',
	'ipadmini',
	'nexus5',
	'galaxys5',
	'macbookpro',
];

export default class DevicePreview extends Component {
	static defaultProps = {
		device: 'iphonex',
		position: '', // landscape
		color: '',
		scale: 1.0,
	};

	constructor(props) {
		super(props);

		this.state = {
			position: props.position,
			color: props.color,
		};
	}

	scaleView() {
		return {
			WebkitTransform: `scale(${this.props.scale})`,
			transform: `scale(${this.props.scale})`,
		};
	}

	wrp(n) {
		return `marvel-device ${n} ${this.state.position} ${this.state.color}`;
	}

	iphonex() {
		return (
			<div className={this.wrp('iphone-x')} style={this.scaleView()}>
				<div className="inner-shadow"></div>
				<div className="screen">{this.props.children}</div>
			</div>
		);
	}

	galaxynote8() {
		return (
			<div className={this.wrp('note8')}>
				<div className="inner"></div>
				<div className="overflow">
					<div className="shadow"></div>
				</div>
				<div className="speaker"></div>
				<div className="sensors"></div>
				<div className="more-sensors"></div>
				<div className="sleep"></div>
				<div className="volume"></div>
				<div className="camera"></div>
				<div className="screen">{this.props.children}</div>
			</div>
		);
	}

	iphone8() {
		return (
			<div className={this.wrp('iphone8')}>
				<div className="top-bar"></div>
				<div className="sleep"></div>
				<div className="volume"></div>
				<div className="camera"></div>
				<div className="sensor"></div>
				<div className="speaker"></div>
				<div className="screen">{this.props.children}</div>
				<div className="home"></div>
				<div className="bottom-bar"></div>
			</div>
		);
	}

	iphone8plus() {
		return (
			<div className={this.wrp('iphone8plus')}>
				<div className="top-bar"></div>
				<div className="sleep"></div>
				<div className="volume"></div>
				<div className="camera"></div>
				<div className="sensor"></div>
				<div className="speaker"></div>
				<div className="screen">{this.props.children}</div>
				<div className="home"></div>
				<div className="bottom-bar"></div>
			</div>
		);
	}

	iphone5c() {
		return (
			<div className={this.wrp('iphone5c')}>
				<div className="top-bar"></div>
				<div className="sleep"></div>
				<div className="volume"></div>
				<div className="camera"></div>
				<div className="sensor"></div>
				<div className="speaker"></div>
				<div className="screen">{this.props.children}</div>
				<div className="home"></div>
				<div className="bottom-bar"></div>
			</div>
		);
	}

	iphone5s() {
		return (
			<div className={this.wrp('iphone5s')}>
				<div className="top-bar"></div>
				<div className="sleep"></div>
				<div className="volume"></div>
				<div className="camera"></div>
				<div className="sensor"></div>
				<div className="speaker"></div>
				<div className="screen">{this.props.children}</div>
				<div className="home"></div>
				<div className="bottom-bar"></div>
			</div>
		);
	}

	ipadmini() {
		return (
			<div className={this.wrp('ipad')}>
				<div className="camera"></div>
				<div className="screen">{this.props.children}</div>
				<div className="home"></div>
			</div>
		);
	}

	macbookpro() {
		return (
			<div className={this.wrp('macbook')}>
				<div className="top-bar"></div>
				<div className="camera"></div>
				<div className="screen">{this.props.children}</div>
				<div className="bottom-bar"></div>
			</div>
		);
	}

	galaxys5() {
		return (
			<div className={this.wrp('s5')}>
				<div className="top-bar"></div>
				<div className="sleep"></div>
				<div className="camera"></div>
				<div className="sensor"></div>
				<div className="speaker"></div>
				<div className="screen">{this.props.children}</div>
				<div className="home"></div>
			</div>
		);
	}

	nexus5() {
		return (
			<div className={this.wrp('nexus5')}>
				<div className="top-bar"></div>
				<div className="sleep"></div>
				<div className="volume"></div>
				<div className="camera"></div>
				<div className="screen">{this.props.children}</div>
			</div>
		);
	}

	render() {
		/* check device-preview */
		const device = DeviceList.find(d => d === this.props.device);

		return (
			<div data-test-id="CT_component_device-preview_test">
				{this[device as string]()}
			</div>
		);
	}
}
