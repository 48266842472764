/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import { FC, forwardRef, Ref } from 'react';
import ReactSelect, {
	components as RSComponent,
	CSSObjectWithLabel,
	GroupBase,
	OptionProps,
	Props,
} from 'react-select';

import { Box, Rating, useColorMode } from '@ctlyst.id/internal-ui';
import { Option as DefaultOption } from '@services/services.types';

import { selectStyles, themeSelect } from './utils';

type PropsValue = 0 | 1 | 2 | 3 | 4 | 5;

export const defaultOptions = [
	{
		value: 5,
		label: 'Bintang 5',
	},
	{
		value: 4,
		label: 'Bintang 4',
	},
	{
		value: 3,
		label: 'Bintang 3',
	},
	{
		value: 2,
		label: 'Bintang 2',
	},
	{
		value: 1,
		label: 'Bintang 1',
	},
];

const RatingOption: FC<OptionProps<DefaultOption>> = ({
	data,
	innerRef,
	innerProps,
}) => (
	<Box
		p="1"
		_hover={{
			bg: 'gray.100',
		}}
		ref={innerRef}
		{...innerProps}
	>
		<Rating value={data.value as PropsValue} />
	</Box>
);

const _SelectRating = <Option,>(
	{ options = defaultOptions as any, styles, ...props }: Props<Option>,
	ref: Ref<HTMLDivElement>
) => {
	const { colorMode } = useColorMode();
	return (
		<ReactSelect
			{...props}
			options={options}
			components={{
				Option: RatingOption as any,
				Control: ({ innerRef, ...controlProps }) => (
					<RSComponent.Control
						innerRef={ref ? ref : innerRef}
						{...controlProps}
					/>
				),
			}}
			styles={{
				...selectStyles(colorMode),
				...styles,
			}}
			theme={themeSelect}
		/>
	);
};

const SelectRating = forwardRef(_SelectRating) as <
	Option,
	IsMulti extends boolean,
	Group extends GroupBase<Option>
>({
	isError = false,
	...props
}: Props<Option, IsMulti, Group> & {
	isError?: boolean;
	ref?: Ref<HTMLDivElement>;
}) => ReturnType<typeof _SelectRating>;

export default SelectRating;
