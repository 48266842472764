import { ReactElement, useEffect, useRef, useState } from 'react';

import { GoogleButtonProps } from './types';

const GoogleButton = ({
	clientId,
	onSuccess,
	onError,
}: GoogleButtonProps): ReactElement => {
	const divRef = useRef<HTMLDivElement>(null);
	const [googleReady, setGoogleReady] = useState(false);

	useEffect(() => {
		const googleIsReady =
			typeof window !== 'undefined' && window.google != null;
		const interval = setInterval(() => {
			if (googleIsReady) {
				setGoogleReady(true);
				clearInterval(interval);
			}
		}, 100);

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		const googleIsNotReady =
			typeof window === 'undefined' ||
			window.google == null ||
			divRef.current == null;
		if (googleIsNotReady) {
			return;
		}

		try {
			window.google.accounts.id.initialize({
				client_id: clientId,
				callback: res => {
					return onSuccess(res.credential);
				},
			});
			window.google.accounts.id.renderButton(divRef.current, {
				type: 'standard',
				locale: 'en_US',
				theme: 'filled_white',
				size: 'medium',
				shape: 'rectangular',
			});
		} catch (error) {
			if (onError != null) {
				onError(error as object);
			}
		}
	}, [clientId, onError, onSuccess, googleReady]);

	return <div ref={divRef} />;
};

export default GoogleButton;
