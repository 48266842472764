import { useMemo } from 'react';

import { LogData } from '@modules/log/types';
import { Pagination } from '@services/services.types';
import { useQuery } from '@tanstack/react-query';
import { ErrorData } from 'axios';

import { getMenuNavigationLog } from './menu-log.service';
import {
	MenuNavigationLogResponse,
	MenuNavigationLogsParams,
} from './menu-log.types';

export const useGetMenuNavigationLogQuery = (
	params: MenuNavigationLogsParams
) => {
	const queryInfo = useQuery<
		MenuNavigationLogResponse,
		ErrorData,
		{ logs: LogData[]; pagination: Pagination }
	>(['menu-navigation-log', params], () => getMenuNavigationLog(params), {
		enabled: !!params.id,
		select: ({ data }) => data,
	});

	return {
		...queryInfo,
		isEmpty: useMemo(() => !queryInfo.data?.logs?.length, [queryInfo.data]),
		data: useMemo(() => queryInfo.data, [queryInfo.data]),
	};
};
