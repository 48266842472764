import { SVGIconProps } from './types';

export const SearchIcon = ({ size = 24, ...rest }: SVGIconProps) => (
	<svg
		data-test-id="CT_component_search-icon_cms"
		xmlns="http://www.w3.org/2000/svg"
		width={size}
		height={size}
		data-icon="search"
		fill="none"
		{...rest}
	>
		<path
			fill="#E0E0E0"
			fillRule="evenodd"
			d="M49.37 79.238c17.324 0 31.368-14.044 31.368-31.369S66.694 16.5 49.37 16.5 18 30.544 18 47.87c0 17.324 14.044 31.368 31.37 31.368Zm0 6c20.637 0 37.368-16.73 37.368-37.369 0-20.638-16.73-37.369-37.369-37.369C28.731 10.5 12 27.23 12 47.87c0 20.637 16.73 37.368 37.37 37.368Z"
			clipRule="evenodd"
		/>
		<path
			fill="#E0E0E0"
			fillRule="evenodd"
			d="M71.771 70.271a3 3 0 0 1 4.243 0L101.12 95.38a3 3 0 1 1-4.242 4.242L71.77 74.514a3 3 0 0 1 0-4.243ZM62.64 36.263a3 3 0 0 1 0 4.243L42.007 61.141a3 3 0 1 1-4.243-4.243l20.635-20.635a3 3 0 0 1 4.243 0Z"
			clipRule="evenodd"
		/>
		<path
			fill="#E0E0E0"
			fillRule="evenodd"
			d="M37.763 36.263a3 3 0 0 0 0 4.243l20.635 20.635a3 3 0 1 0 4.242-4.243L42.006 36.263a3 3 0 0 0-4.243 0Z"
			clipRule="evenodd"
		/>
		<path
			fill="#E0E0E0"
			fillRule="evenodd"
			d="M49.37 79.238c17.324 0 31.368-14.044 31.368-31.369S66.694 16.5 49.37 16.5 18 30.544 18 47.87c0 17.324 14.044 31.368 31.37 31.368Zm0 6c20.637 0 37.368-16.73 37.368-37.369 0-20.638-16.73-37.369-37.369-37.369C28.731 10.5 12 27.23 12 47.87c0 20.637 16.73 37.368 37.37 37.368Z"
			clipRule="evenodd"
		/>
		<path
			fill="#E0E0E0"
			fillRule="evenodd"
			d="M71.771 70.271a3 3 0 0 1 4.243 0L101.12 95.38a3 3 0 1 1-4.242 4.242L71.77 74.514a3 3 0 0 1 0-4.243ZM62.64 36.263a3 3 0 0 1 0 4.243L42.007 61.141a3 3 0 1 1-4.243-4.243l20.635-20.635a3 3 0 0 1 4.243 0Z"
			clipRule="evenodd"
		/>
		<path
			fill="#E0E0E0"
			fillRule="evenodd"
			d="M37.764 36.263a3 3 0 0 0 0 4.243l20.634 20.635a3 3 0 1 0 4.243-4.243L42.006 36.263a3 3 0 0 0-4.242 0Z"
			clipRule="evenodd"
		/>
		<path
			fill="#111"
			fillRule="evenodd"
			d="M45.37 79.238c17.324 0 31.368-14.044 31.368-31.369S62.694 16.5 45.37 16.5 14 30.544 14 47.87c0 17.324 14.044 31.368 31.37 31.368Zm0 6c20.637 0 37.368-16.73 37.368-37.369 0-20.638-16.73-37.369-37.369-37.369C24.731 10.5 8 27.23 8 47.87c0 20.637 16.73 37.368 37.37 37.368Z"
			clipRule="evenodd"
		/>
		<path
			fill="#111"
			fillRule="evenodd"
			d="M67.771 70.271a3 3 0 0 1 4.243 0L97.12 95.38a3 3 0 1 1-4.242 4.242L67.77 74.514a3 3 0 0 1 0-4.243ZM58.64 36.263a3 3 0 0 1 0 4.243L38.007 61.141a3 3 0 1 1-4.243-4.243l20.635-20.635a3 3 0 0 1 4.243 0Z"
			clipRule="evenodd"
		/>
		<path
			fill="#111"
			fillRule="evenodd"
			d="M33.763 36.263a3 3 0 0 0 0 4.243l20.635 20.635a3 3 0 1 0 4.242-4.243L38.006 36.263a3 3 0 0 0-4.243 0Z"
			clipRule="evenodd"
		/>
		<path
			fill="#111"
			fillRule="evenodd"
			d="M45.37 79.238c17.324 0 31.368-14.044 31.368-31.369S62.694 16.5 45.37 16.5 14 30.544 14 47.87c0 17.324 14.044 31.368 31.37 31.368Zm0 6c20.637 0 37.368-16.73 37.368-37.369 0-20.638-16.73-37.369-37.369-37.369C24.731 10.5 8 27.23 8 47.87c0 20.637 16.73 37.368 37.37 37.368Z"
			clipRule="evenodd"
		/>
		<path
			fill="#111"
			fillRule="evenodd"
			d="M67.771 70.271a3 3 0 0 1 4.243 0L97.12 95.38a3 3 0 1 1-4.242 4.242L67.77 74.514a3 3 0 0 1 0-4.243ZM58.64 36.263a3 3 0 0 1 0 4.243L38.007 61.141a3 3 0 1 1-4.243-4.243l20.635-20.635a3 3 0 0 1 4.243 0Z"
			clipRule="evenodd"
		/>
		<path
			fill="#111"
			fillRule="evenodd"
			d="M33.764 36.263a3 3 0 0 0 0 4.243l20.634 20.635a3 3 0 1 0 4.243-4.243L38.006 36.263a3 3 0 0 0-4.242 0Z"
			clipRule="evenodd"
		/>
	</svg>
);
