import Axios, { ResponseData } from 'axios';

import {
	MenuNavigationDetailPayload,
	MenuNavigationDetailResponse,
	MenuNavigationResponse,
	MenuResponse,
} from './menu.types';

export const getMenu = () => {
	return Axios.get<MenuResponse>(`/menu`);
};

export const getMenuNavigation = () => {
	return Axios.get<MenuNavigationResponse>(`/menu_navigations`);
};

export const getMenuNavigationById = (id: number) => {
	return Axios.get<MenuNavigationDetailResponse>(`/menu_navigations/${id}`);
};

export const updateMenuNavigationById = (
	id: number,
	data: MenuNavigationDetailPayload
) => {
	return Axios.put<ResponseData<null>>(`/menu_navigations/${id}`, data);
};
