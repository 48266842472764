import { ReactElement, useCallback } from 'react';
import { toast } from 'react-toastify';

import { Box, Card, Flex, Text, VStack } from '@ctlyst.id/internal-ui';
import { onLogin } from '@modules/auth/utils/helpers';
import getRuntimeEnv from '@utils/env';
import axios from 'axios';
import jwt from 'jsonwebtoken';

import { AppHead } from '@components';
import { GoogleLogin } from '@components/google-login';
import { NextPageWithLayout } from '@root/src/pages/_app';

const Login: NextPageWithLayout = () => {
	const googleClientId = getRuntimeEnv('AUTH_GOOGLE_CLIENT_ID', '');
	const xmsApiUrl = getRuntimeEnv('XMS_API_URL', '');

	const onAuthLogin = useCallback(
		async (creds: string) => {
			try {
				const googleResponse = jwt.decode(creds, { json: true });
				const response = await axios.post<any>(xmsApiUrl + '/auth/login', {
					type: 'google',
					username: googleResponse?.email,
					password: googleResponse?.sub,
				});
				onLogin(response.data?.token, response.data?.refresh_token);
			} catch (error) {
				toast.error('Gagal login. Silakan coba kembali.');
				console.log(error);
			}
		},
		[xmsApiUrl]
	);

	return (
		<>
			<AppHead />
			<Flex minH="100vh" justify="center" align="center">
				<Card bg="white" w="400px" rounded="2xl" px="4" py="8">
					<VStack direction="row" textAlign="center">
						<Flex flexDir="column" maxW="500px" gap="1">
							<Text fontSize="lg" color="primary.500">
								Selamat Datang di CMS voilà.id
							</Text>
							<Text fontSize="xs" color="neutral.700">
								Login ke akun Anda untuk mulai menggunakan.
							</Text>
						</Flex>
						<Box py={10}>
							<GoogleLogin
								data-test-id="CT_module_login_google-login"
								clientId={googleClientId}
								onSuccess={creds => onAuthLogin(creds)}
							/>
						</Box>
						<Box>
							<Text fontSize="xs" color="neutral.700">
								Hubungi manager jika akun Anda belum terdaftar.
							</Text>
						</Box>
					</VStack>
				</Card>
				<Box pos="absolute" bottom="5">
					<Text fontSize="2xs" color="neutral.700">
						© 2024 Voila CMS All rights reserved.
					</Text>
				</Box>
			</Flex>
		</>
	);
};

Login.publicRoute = (page: ReactElement) => {
	return page;
};

export default Login;
