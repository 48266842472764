/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	ReactNode,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { Close as X } from '@ctlyst.id/internal-icon';
import {
	Box,
	Button,
	Flex,
	FlexProps,
	FormControl,
	FormLabel,
	Heading,
	Image as ChakraImage,
	ListItem,
	RequiredIndicator,
	Text,
	UnorderedList,
} from '@ctlyst.id/internal-ui';
import voilaTheme from '@ctlyst.id/voila-theme';

import {
	DEFAULT_IMAGE_MIME_TYPE,
	formatValidationMessage,
	messages,
} from './constants';

export type BaseImageUploaderProps = FlexProps & {
	/**
	 * callback when validation is valid
	 */
	onHandleUploadFile?: (
		file: File | null,
		image: HTMLImageElement | null
	) => void;

	/**
	 * callback when validation is not valid
	 */
	onHandleRejections?: (file: File, image: HTMLImageElement | null) => void;

	/**
	 * Text for drag active
	 * - if `dragActiveText` is undefined. Text will take from default constant.
	 */
	dragActiveText?: string;

	/**
	 * Text for drag inactive
	 * - if `dragInActiveText` is undefined. Text will take from default constant.
	 */
	dragInActiveText?: string;

	/**
	 * Text for drag replace
	 * - if `dragReplaceText` is undefined. Text will take from default constant.
	 */
	dragReplaceText?: string;

	/**
	 * Text for upload file
	 * - if `uploadFileText` is undefined. Text will take from default constant.
	 */
	uploadFileText?: string;

	/**
	 * Text for button preview
	 * - if `changeFileText` is undefined. Text will take from default constant.
	 */
	changeFileText?: string;

	label?: string | ReactNode;

	isRequired?: boolean;

	helperText?: string[] | ReactNode;

	value?: File | string;

	/**
	 * validate width x height dimension
	 */
	dimension?: {
		/**
		 * [width, height]
		 */
		validate: [number, number];
		message: string;
	};

	/**
	 * validate max file size
	 */
	maxFileSize?: {
		/**
		 * in bytes
		 */
		validate: number;
		message: string;
	};

	/**
	 * validate image format
	 * @default acceptFormat.validate - @see DEFAULT_IMAGE_MIME_TYPE
	 * @default acceptFormat.message - Hanya diperbolehkan upload gambar
	 */
	acceptFormat?: {
		/**
		 * Example :
		 * ['.jpg', '.png', '.jpeg', ...]
		 */
		validate: string[];
		message?: string;
	};

	/**
	 * Custom validation
	 * custom validation will run first
	 */
	customValidation?: (file: File, image: HTMLImageElement) => string | null;

	isShowCloseButton?: boolean;
	isClosableToast?: boolean;

	isError?: boolean;
	errorText?: string;

	/**
	 * provide unique test id
	 */
	testId?: string;
	isShowReupload?: boolean;
};

const BaseImageUploader: React.FC<BaseImageUploaderProps> = ({
	onHandleUploadFile,
	onHandleRejections,
	dragActiveText,
	dragInActiveText,
	dragReplaceText,
	changeFileText,
	uploadFileText,
	helperText,
	label,
	isRequired,
	dimension,
	acceptFormat = {
		validate: DEFAULT_IMAGE_MIME_TYPE,
	},
	maxFileSize,
	customValidation,
	value,
	isShowCloseButton,
	isClosableToast,
	testId,
	isError,
	errorText,
	isShowReupload,
	...props
}) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [filePreview, setFilePreview] = useState<string>();

	const handleRejection = useCallback(
		(message: string, file: File, image: HTMLImageElement | null) => {
			if (onHandleRejections) {
				onHandleRejections(file, image);
			}

			return toast.error(message, {
				closeButton: isClosableToast,
			});
		},
		[onHandleRejections]
	);

	const onDrop = useCallback(
		(files: File[]) => {
			const file = files[0];
			const fileFormat = file.name.split('.').pop() as string;

			// If user not upload image mime type, just return validation error
			// error message is either default or custom from props
			if (!acceptFormat?.validate.includes(fileFormat)) {
				return handleRejection(
					acceptFormat.message ??
						formatValidationMessage(acceptFormat.validate),
					file,
					null
				);
			}

			const imageUrl = URL.createObjectURL(file);
			const img = new Image();

			img.src = imageUrl as string;

			img.onload = () => {
				const imgWidth = img.width;
				const imgheight = img.height;

				// validate by provided custom validator
				if (customValidation && customValidation(file, img) !== null) {
					return handleRejection(
						customValidation(file, img) as string,
						file,
						img
					);
				}

				// validate file format
				if (!acceptFormat.validate.includes(fileFormat)) {
					return handleRejection(
						acceptFormat.message ??
							formatValidationMessage(acceptFormat.validate),
						file,
						img
					);
				}

				// validate image dimension
				if (
					dimension &&
					(imgWidth !== dimension.validate[0] ||
						imgheight !== dimension.validate[1])
				) {
					return handleRejection(dimension.message, file, img);
				}

				if (maxFileSize && file.size > maxFileSize.validate) {
					return handleRejection(maxFileSize.message, file, null);
				}

				onHandleUploadFile?.(file, img);
				setFilePreview(URL.createObjectURL(file));
			};
		},
		[
			acceptFormat,
			customValidation,
			dimension,
			handleRejection,
			maxFileSize,
			onHandleUploadFile,
		]
	);

	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			onDrop,
			maxFiles: 1,
		});

	const renderHelperText = () => {
		if (Array.isArray(helperText)) {
			return (
				<UnorderedList
					pl={2}
					fontSize={12}
					color="gray.600"
					data-test-id="CT_component_base-image-uploader_helperText"
				>
					{helperText.map(text => (
						<ListItem key={text}>{text}</ListItem>
					))}
				</UnorderedList>
			);
		}

		return helperText;
	};

	const renderErrorText = (text: string | undefined) => (
		<Box mb={2}>
			<Text fontSize={12} color="danger.500">
				{text}
			</Text>
		</Box>
	);

	const handleRemove = () => {
		setFilePreview(undefined);
		onHandleUploadFile?.(null, null);
		acceptedFiles.pop();
	};

	/**
	 * Immediately set file preview if value exist
	 */
	useEffect(() => {
		if (value) {
			if (typeof value === 'string') {
				setFilePreview(value);
			} else {
				setFilePreview(URL.createObjectURL(value));
			}
		} else {
			setFilePreview(undefined);
			acceptedFiles.pop();
		}
	}, [value]);

	if (filePreview) {
		return (
			<FormControl isRequired={isRequired}>
				{label && (
					<>
						{typeof label === 'string' ? (
							<FormLabel requiredIndicator={<></>} fontSize="text.sm" mb="2">
								{isRequired && <RequiredIndicator mr={1} ml={0} />}
								{label}
							</FormLabel>
						) : (
							label
						)}
					</>
				)}
				<Flex
					justifyContent="center"
					flexDir="column"
					alignItems="center"
					gap={4}
					mb={2}
					p={5}
					position="relative"
					border="1px dashed"
					rounded="md"
					borderColor="primary.500"
					maxH="160"
				>
					{isShowCloseButton && (
						<Box position="absolute" top={2} right={2}>
							<Button
								data-test-id={`CT_component_base-image-uploader_remove-image${
									testId ? `_${testId}` : ''
								}`}
								aria-label="remove-image"
								rounded={'full'}
								variant="icon"
								bg="neutral.600"
								size="sm"
								zIndex={1}
								onClick={handleRemove}
							>
								<X size={3} color="white" />
							</Button>
						</Box>
					)}

					<Box w="full">
						<Flex
							position="relative"
							data-test-id={`CT_component_base-image-uploader_image-preview${
								testId ? `_${testId}` : ''
							}`}
							justify="center"
							align="center"
						>
							<ChakraImage
								h="140"
								src={filePreview}
								alt="uploader-preview"
								objectFit={'contain'}
							/>
						</Flex>
					</Box>
				</Flex>
				<Flex align="center" justify="center" my={2}>
					<input
						{...getInputProps()}
						key={Math.random()}
						ref={inputRef}
						accept="image/*"
						data-test-id={`CT_component_base-image-uploader_change-img${
							testId ? `_${testId}` : ''
						}`}
					/>

					{isShowReupload && (
						<Button
							data-test-id={`CT_component_base-image-uploader_change-img-btn${
								testId ? `_${testId}` : ''
							}`}
							type="button"
							variant="outline"
							onClick={() => {
								inputRef?.current?.click();
							}}
						>
							Ubah Foto
						</Button>
					)}
				</Flex>
				{renderErrorText(errorText)}
				{renderHelperText()}
			</FormControl>
		);
	}

	return (
		<FormControl isRequired={isRequired}>
			{label && (
				<>
					{typeof label === 'string' ? (
						<FormLabel requiredIndicator={<></>} fontSize="text.sm">
							{isRequired && <RequiredIndicator mr={1} ml={0} />}
							{label}
						</FormLabel>
					) : (
						label
					)}
				</>
			)}
			<Flex
				minH="160"
				mb={2}
				bg="white"
				border="1px dashed"
				rounded="md"
				borderColor={isError ? 'danger.500' : 'primary.500'}
				alignItems="center"
				justifyContent="center"
				cursor="pointer"
				{...props}
				{...getRootProps()}
				sx={{
					'&:focus': {
						outline: 'solid',
						outlineColor: voilaTheme.colors.danger[500],
						outlineWidth: '1px',
					},
				}}
			>
				<input
					data-test-id={`CT_component_base-image-uploader_input-file${
						testId ? `_${testId}` : ''
					}`}
					{...getInputProps()}
				/>

				{isDragActive ? (
					<Text>{dragActiveText ?? messages.dragActive}</Text>
				) : (
					<Flex
						flexDirection="column"
						alignItems="center"
						color={isError ? 'danger.500' : 'primary.500'}
					>
						{!Boolean(filePreview) && (
							<Heading fontWeight="400" fontSize="xl" mb="2" textAlign="center">
								{uploadFileText ?? messages.uploadFile}
							</Heading>
						)}

						{Boolean(filePreview) ? (
							<Text fontSize={12}>
								{dragReplaceText ?? messages.dragReplace}
							</Text>
						) : (
							<Text fontSize={12}>
								{dragInActiveText ?? messages.dragInActive}
							</Text>
						)}
					</Flex>
				)}
			</Flex>
			{renderErrorText(errorText)}
			{renderHelperText()}
		</FormControl>
	);
};

BaseImageUploader.defaultProps = {
	isShowReupload: true,
};

export default BaseImageUploader;
