import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { ErrorData } from 'axios';

import { requestFn } from '../client';

import { Privilege, PrivilegeResponse } from './user.types';

export function useGetPrivilegeQuery() {
	const queryInfo = useQuery<PrivilegeResponse, ErrorData, Privilege>(
		['privilege'],
		async () =>
			requestFn<PrivilegeResponse>({
				url: `/user/privilege`,
				method: 'get',
			}),
		{
			select: data => data.data,
		}
	);
	if (!queryInfo.data?.userRole && queryInfo.data?.role) {
		queryInfo.data.userRole = queryInfo.data.role;
	}
	return {
		...queryInfo,
		data: useMemo(() => queryInfo.data, [queryInfo.data]),
	};
}
