import React from 'react';

import { Box, Flex } from '@ctlyst.id/internal-ui';

type Props = React.PropsWithChildren;

const LayoutBlank = ({ children }: Props) => {
	// PAGE WITH NO HEADERS
	return (
		<Box data-test-id="CT_module_layout-blank_page">
			<Flex as="main" px={5} py={7}>
				{children}
			</Flex>
		</Box>
	);
};

export default LayoutBlank;
