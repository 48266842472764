import React, { FC, ReactNode } from 'react';

import {
	Button,
	ButtonGroup,
	Modal as ChakraModal,
	ModalBody,
	ModalBodyProps,
	ModalCloseButton,
	ModalContent,
	ModalContentProps,
	ModalFooter,
	ModalHeader,
	ModalHeaderProps,
	ModalOverlay,
	ModalProps as ChakraModalProps,
	ThemeTypings,
} from '@ctlyst.id/internal-ui';

export interface ModalProps extends ChakraModalProps {
	/**
	 * Create Footer Custom Component.
	 * - If use this props. `onAction` & `onSecondaryAction` not be renders and execute.
	 */
	footer?: ReactNode;
	/**
	 * Create Header Text or Header Custom Component.
	 * - If value is `string`, then will render text inside `<ModalHeader />`
	 * - If value is `ReactNode`, then will render custom component. *`ModalHeader` not included.
	 */
	header?: string | ReactNode;
	showCloseButton?: boolean;
	/**
	 * Create Primary Action Button
	 */
	onAction?: () => void;
	/**
	 * Create Secondary Action Button (usually for cancel action)
	 */
	onSecondaryAction?: () => void;
	/**
	 * Text for action button
	 * - if `onAction` is undefined. Text not to be show.
	 */
	actionText?: string;
	/**
	 * Text for secondary action button
	 * - if `onSecondaryAction` is undefined. Text not to be show.
	 */
	secondaryActionText?: string;
	/**
	 *
	 * Custom Modal Content Props
	 * - extends props from `ModalContentProps`
	 */
	contentStyles?: ModalContentProps;
	headerStyles?: ModalHeaderProps;
	bodyStyles?: ModalBodyProps;
	isLoading?: boolean;
	isDisabled?: boolean;
	secondaryActionColorScheme?: ThemeTypings['colorSchemes'];
	testId?: string;
	footerText?: ReactNode;
}
const Modal: FC<ModalProps> = ({
	isOpen,
	onClose,
	children,
	footer,
	header,
	showCloseButton = true,
	onAction,
	onSecondaryAction,
	actionText = 'OK',
	secondaryActionText = 'Batal',
	secondaryActionColorScheme,
	contentStyles,
	colorScheme = 'primary',
	isLoading,
	isDisabled,
	headerStyles,
	bodyStyles,
	testId,
	footerText,
	...rest
}) => (
	<ChakraModal isOpen={isOpen} onClose={onClose} size={'sm'} {...rest}>
		<ModalOverlay
			data-test-id={`CT_component_modal_overlay${testId ? `_${testId}` : ''}`}
		/>
		<ModalContent
			w={contentStyles?.w || contentStyles?.width || 500}
			maxW="initial"
			position="relative"
			bg="white"
			{...contentStyles}
		>
			{header && (
				<>
					{typeof header === 'string' ? (
						<ModalHeader
							fontSize="text.lg"
							fontWeight="normal"
							whiteSpace="nowrap"
							textOverflow="ellipsis"
							bg="neutral.200"
							paddingTop={2}
							paddingBottom={2}
							{...headerStyles}
						>
							{header}
						</ModalHeader>
					) : (
						header
					)}
				</>
			)}

			{showCloseButton && (
				<ModalCloseButton
					data-test-id={`CT_component_modal_action-close${
						testId ? `_${testId}` : ''
					}`}
					bg="white"
					transition="all ease 0.2s"
					position="absolute"
					boxShadow="none"
					right={2}
					top={2}
					_hover={{
						boxShadow: 'base',
					}}
					_active={{
						backgroundColor: 'white',
					}}
				/>
			)}

			<ModalBody data-test-id="CT_component_modal_body" {...bodyStyles}>
				{children}
			</ModalBody>
			{footer ? (
				<>{footer}</>
			) : (
				(onAction || onSecondaryAction) && (
					<ModalFooter
						data-test-id="CT_component_modal_footer"
						bg="neutral.100"
						gap={3}
						zIndex="1"
					>
						{footerText}
						<ButtonGroup
							data-test-id={`CT_component_modal_action-group_${colorScheme}`}
							colorScheme={colorScheme}
						>
							{onSecondaryAction && (
								<Button
									data-test-id={`CT_component_modal_secondary-action${
										testId ? `_${testId}` : ''
									}`}
									variant="outline"
									onClick={onSecondaryAction}
									colorScheme={secondaryActionColorScheme}
									fontSize={14}
								>
									{secondaryActionText}
								</Button>
							)}
							{onAction && (
								<Button
									data-test-id={`CT_component_modal_action${
										testId ? `_${testId}` : ''
									}`}
									onClick={onAction}
									isLoading={isLoading}
									isDisabled={isDisabled}
									colorScheme={colorScheme}
									fontSize={14}
								>
									{actionText}
								</Button>
							)}
						</ButtonGroup>
					</ModalFooter>
				)
			)}
		</ModalContent>
	</ChakraModal>
);
export default Modal;
