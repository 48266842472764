import * as React from 'react';

import { SVGIconProps } from './types';

const SingleOptionIcon = ({ size = 24, ...rest }: SVGIconProps) => (
	<svg
		width={size}
		height={size}
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<g clip-path="url(#clip0_2835_1746)">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.00033 1.99984C4.68662 1.99984 2.00033 4.68613 2.00033 7.99984C2.00033 11.3135 4.68662 13.9998 8.00033 13.9998C11.314 13.9998 14.0003 11.3135 14.0003 7.99984C14.0003 4.68613 11.314 1.99984 8.00033 1.99984ZM0.666992 7.99984C0.666992 3.94975 3.95024 0.666504 8.00033 0.666504C12.0504 0.666504 15.3337 3.94975 15.3337 7.99984C15.3337 12.0499 12.0504 15.3332 8.00033 15.3332C3.95024 15.3332 0.666992 12.0499 0.666992 7.99984Z"
				fill="#111111"
			/>
			<path
				d="M4.43066 8.00005C4.43066 6.029 6.02851 4.43115 7.99956 4.43115C9.97061 4.43115 11.5685 6.029 11.5685 8.00005C11.5685 9.9711 9.97061 11.5689 7.99956 11.5689C6.02851 11.5689 4.43066 9.9711 4.43066 8.00005Z"
				fill="#111111"
			/>
		</g>
		<defs>
			<clipPath id="clip0_2835_1746">
				<rect width="16" height="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default SingleOptionIcon;
