import React from 'react';

import { Box, Flex, Header } from '@ctlyst.id/internal-ui';
import { onLogout } from '@modules/auth/utils/helpers';
import { useGetPrivilegeQuery } from '@services/user';

import { Navigation } from '@components';

type Props = React.PropsWithChildren;

const Layout = ({ children }: Props) => {
	const { data: headerData } = useGetPrivilegeQuery();

	const mainLogo = 'https://assets.voila.id/xms/logo-xms.jpg';
	const centerLogo = 'https://assets.voila.id/xms/logo-voila-black.png?v=1';

	return (
		<Box data-test-id="CT_module_layout_page" minH="100vh">
			<Box
				position="fixed"
				top={0}
				left={0}
				zIndex={999}
				right={0}
				bg="bg-default"
			>
				<Header
					background="white"
					hideSwitchMode
					color="primary"
					data={headerData}
					mainLogo={mainLogo}
					centerLogo={centerLogo}
					mainLogoSize="7"
					onLogout={onLogout}
				/>
				<Navigation />
			</Box>
			<Flex as="main" pt="7.625rem" px={6} pb={5}>
				{children}
			</Flex>
		</Box>
	);
};

export default Layout;
