/* eslint-disable testing-library/consistent-data-testid */
import type { GroupHeadingProps, MultiValue, OptionProps } from 'react-select';
import { components as ComponentRS } from 'react-select';

import { Checkbox, Flex, Text } from '@ctlyst.id/internal-ui';

import SelectWithCheckboxBase, {
	CHECKBOX_STATE,
	getSelectAllCheckboxState,
	OptionGroup,
	SelectWithCheckboxBaseProps,
} from './base-select-checkbox';

export const optionWithSelectAllTransform = <Option,>(
	options: OptionGroup<Option>[]
) => {
	return [
		{
			label: `Pilih Semua`,
			value: 0,
			selectAllCheckbox: true,
		},
		...options,
	];
};

export const GroupHeading = <T,>(props: GroupHeadingProps<T>) => (
	<Flex alignItems="center" textStyle="text.sm" fontWeight={'semibold'}>
		<ComponentRS.GroupHeading {...props} />
	</Flex>
);

const InputOption = <T, IsMulti extends boolean>({
	getStyles,
	isDisabled,
	isFocused,
	isSelected,
	children,
	innerProps,
	data,
	checkedState,
	isMulti,
	...rest
}: OptionProps<OptionGroup<T>, IsMulti> & {
	checkedState: number;
}) => {
	// TODO fix type
	const style = {
		alignItems: 'center',
		color: 'inherit',
		display: 'flex ',
	};

	// prop assignment
	const props: JSX.IntrinsicElements['div'] = {
		...innerProps,
		'aria-disabled': isDisabled,
		onClick: isDisabled ? undefined : innerProps.onClick,
		style,
	};

	return (
		<>
			<ComponentRS.Option
				isMulti={true}
				data={data}
				isDisabled={isDisabled}
				isFocused={isFocused}
				isSelected={isSelected}
				getStyles={getStyles}
				innerProps={props}
				{...rest}
			>
				<Flex
					alignItems="center"
					width="100%"
					mb="3px"
					gap={3}
					data-test-id="CT_component_select-checkbox_options"
					cursor={isDisabled ? 'not-allowed' : 'default'}
				>
					{data?.selectAllCheckbox ? (
						<Checkbox
							isChecked={checkedState === CHECKBOX_STATE.CHECKED}
							isIndeterminate={checkedState === CHECKBOX_STATE.INDETERMINATE}
							isDisabled={isDisabled}
							data-test-id="CT_component_select-checkbox_select-all-option"
						/>
					) : (
						<Checkbox
							isChecked={isSelected}
							mr="8px"
							data-test-id="CT_component_select-checkbox_option-checkbox"
						/>
					)}
					<Text
						textStyle={'text.sm'}
						data-test-id={`select-checkbox-option-label-${data.value}`}
					>
						{children}
					</Text>
				</Flex>
			</ComponentRS.Option>
			{data?.selectAllCheckbox ? <hr /> : null}
		</>
	);
};

const SelectCheckbox = <T, IsMulti extends boolean = boolean>(
	props: SelectWithCheckboxBaseProps<OptionGroup<T>, IsMulti>
) => {
	const { isMulti, ...rest } = props;
	const value = (props.value ? props.value : []) as MultiValue<OptionGroup<T>>;

	return (
		<SelectWithCheckboxBase
			isMulti={isMulti}
			options={props.options}
			components={{
				GroupHeading: GroupHeading,
				Option: optionProps => {
					const optionLength = props.options?.length
						? props.options.length - 1
						: 0;
					const selectedValue = optionProps.isSelected
						? CHECKBOX_STATE.CHECKED
						: CHECKBOX_STATE.UNCHECKED;
					const checkedState = optionProps.data.selectAllCheckbox
						? getSelectAllCheckboxState(value.length, optionLength)
						: selectedValue;
					return <InputOption {...optionProps} checkedState={checkedState} />;
				},
			}}
			{...rest}
		/>
	);
};

export default SelectCheckbox;
