import React, { ReactNode, useContext } from 'react';

import { getCookie } from 'cookies-next';

import { ACCESS_TOKEN } from './constants';

type AuthContextValue = {
	isAuthenticated: boolean;
};

const AuthContext = React.createContext<AuthContextValue>(null as never);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const isAuthenticated = !!getCookie(ACCESS_TOKEN);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
