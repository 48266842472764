import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
	DragDropContext,
	DraggingStyle,
	Droppable,
	DropResult,
	NotDraggingStyle,
} from 'react-beautiful-dnd';

import { Plus } from '@ctlyst.id/internal-icon';
import {
	Flex,
	FormControl,
	FormLabel,
	Image as ChakraImage,
	ListItem,
	RequiredIndicator,
	Text,
	UnorderedList,
	useDisclosure,
	VStack,
} from '@ctlyst.id/internal-ui';
import { isRatioEqual, reorderArray } from '@utils';

import { Modal } from '@components';

import PreviewImage from './preview-image';

export interface MultipleImagesProps {
	label?: string;
	isRequired?: boolean;
	maxLength: number;
	helperText?: string[];
	invalidImageMessage?: string;
	dimension: {
		validate: [number, number];
		message: string;
	};
	maxFileSize?: {
		validate: number;
		message: string;
	};
	acceptFormat?: {
		validate: string[];
		message: string;
	};
	onChange: (file: File) => Promise<any>;
	onSuccess: (data: string[]) => void;
	onError: (message?: string) => void;
	value: string[];
}

const getListStyle = (
	isDragging: boolean,
	draggableStyle?: DraggingStyle | NotDraggingStyle
) => ({
	display: 'flex',
	overflow: 'auto',
	...draggableStyle,
});

const MultipleImages = ({
	label,
	isRequired,
	helperText,
	invalidImageMessage,
	maxLength,
	acceptFormat,
	maxFileSize,
	dimension,
	onChange,
	onSuccess,
	onError,
	value,
}: MultipleImagesProps) => {
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [filePreview, setFilePreview] = useState<string[]>([]);
	const [selectedImage, setSelectedImage] = useState<string>('');
	const { isOpen, onOpen, onClose } = useDisclosure();

	const isMaxLength = filePreview.length === maxLength;

	const handleChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files;

		if (fileList) {
			const fileArray: File[] = Array.from(fileList);

			const filePreviewLength = filePreview.length;
			const sliceLength = maxLength - filePreviewLength;

			const acceptedFiles = await Promise.all(
				fileArray.map(async tempFile => {
					const imageUrl = URL.createObjectURL(tempFile);

					const fileFormat = tempFile.name.split('.').pop() as string;
					const acceptedFormat = acceptFormat?.validate.includes(fileFormat);

					// validate file format
					if (fileList.length === 1 && !acceptedFormat) {
						onError(acceptFormat?.message);
						return null;
					}

					const img = new Image();
					img.src = imageUrl as string;

					// Wrap image load in a Promise to handle asynchronously
					const imageLoadPromise = new Promise<File | null>(resolve => {
						img.onload = () => resolve(tempFile);
						img.onerror = () => resolve(null); // In case image fails to load
					});

					// Wait for image load Promise to resolve
					const loadedImage = await imageLoadPromise;

					if (!loadedImage) {
						return null;
					}

					const imgWidth = img.width;
					const imgHeight = img.height;
					const fileSize = tempFile.size;

					// validate file ratio
					const isRatioValid = isRatioEqual(
						{ width: imgWidth, height: imgHeight },
						{ width: dimension.validate[0], height: dimension.validate[1] }
					);

					const isSizeValid = fileSize <= Number(maxFileSize?.validate);

					// validate ratio & size
					if (fileList.length === 1 && !isRatioValid && !isSizeValid) {
						onError(invalidImageMessage);
						return null;
					}

					// validate size
					if (fileList.length === 1 && !isSizeValid) {
						onError(maxFileSize?.message);
						return null;
					}

					// validate ratio
					if (fileList.length === 1 && !isRatioValid) {
						onError(dimension.message);
						return null;
					}

					if (isRatioValid && isSizeValid && acceptFormat) {
						return loadedImage;
					}
				})
			);

			let validImages = acceptedFiles.filter(
				file => file !== null && typeof file !== 'undefined'
			) as File[];

			if (validImages.length > maxLength || validImages.length > sliceLength) {
				validImages = validImages.slice(0, sliceLength);

				onError(`Jumlah foto maksimal ${maxLength}.`);
			}

			if (validImages.length > 0) {
				const previewImg = validImages.map(item => URL.createObjectURL(item));
				const newPreview = filePreview.concat(previewImg);

				setFilePreview(newPreview);

				try {
					const uploadPromises = validImages.map(onChange);
					const newImages = await Promise.all(uploadPromises);
					const uploadedImages = newImages.filter(
						image => typeof image === 'string'
					);

					const joinFilePreview = filePreview.concat(uploadedImages);

					setFilePreview(joinFilePreview);
					onSuccess(joinFilePreview);
				} catch (error) {
					console.error('Error during upload:', error);
					throw error;
				}
			}

			if (
				acceptedFiles.length > 1 &&
				acceptedFiles.some(file => file === undefined || file === null)
			) {
				return onError(
					'Beberapa foto tidak dapat di-upload karena tidak sesuai dengan ketentuan.'
				);
			}
		}
	};

	const handleUpload = () => {
		if (inputRef?.current) {
			inputRef.current?.click();
		}
	};

	const handleRemoveFile = useCallback(
		(index: number) => {
			const newImage = filePreview.filter((_, i) => i !== index);

			onSuccess(newImage);
			setFilePreview(newImage);
		},
		[setFilePreview, filePreview, onSuccess]
	);

	const handleDragEnd = async (result: DropResult) => {
		const childData = filePreview;
		const reindexChildList = reorderArray(
			childData,
			result.source.index,
			result.destination?.index ?? 0
		);

		onSuccess(reindexChildList);
		setFilePreview(reindexChildList);
	};

	const handlePreview = (src: string) => {
		setSelectedImage(src);
		onOpen();
	};

	const renderHelperText = () => {
		if (Array.isArray(helperText)) {
			return (
				<UnorderedList
					pl={2}
					fontSize={12}
					color="gray.600"
					data-test-id="CT_component_multiple-images_helperText"
				>
					{helperText.map(text => (
						<ListItem key={text}>{text}</ListItem>
					))}
				</UnorderedList>
			);
		}

		return helperText;
	};

	useEffect(() => {
		setFilePreview(value);
	}, [value]);

	return (
		<FormControl>
			{label && (
				<FormLabel fontSize="text.sm">
					{isRequired && <RequiredIndicator mr={1} ml={0} />}
					{label}
				</FormLabel>
			)}
			<Flex align="center" my="3">
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="droppable" direction="horizontal">
						{(droppableProvided, droppableSnapshot) => (
							<Flex
								{...droppableProvided.droppableProps}
								ref={droppableProvided.innerRef}
								style={getListStyle(droppableSnapshot.isDraggingOver)}
							>
								{filePreview.map((src, i) => (
									<PreviewImage
										src={src}
										key={i}
										index={i}
										onPreview={handlePreview}
										onRemove={handleRemoveFile}
									/>
								))}
								{droppableProvided.placeholder}
								<Flex
									data-test-id=""
									align="center"
									justify="center"
									h="120px"
									w="120px"
									userSelect="none"
									border="1px"
									borderColor={isMaxLength ? 'neutral.500' : 'primary.500'}
									color={isMaxLength ? 'neutral.500' : 'primary.500'}
									pointerEvents={isMaxLength ? 'none' : 'initial'}
									borderStyle="dashed"
									rounded="md"
									cursor="pointer"
									onClick={handleUpload}
								>
									<VStack>
										<Plus color="primary.500" />
										<Text>
											({filePreview.length}/{maxLength})
										</Text>
									</VStack>
									<input
										hidden
										data-test-id="CT_component_multiple-images_upload"
										type="file"
										multiple
										ref={inputRef}
										accept="image/*"
										onChange={handleChangeFile}
										onClick={e => {
											(e.target as HTMLInputElement).value = '';
										}}
									/>
								</Flex>
							</Flex>
						)}
					</Droppable>
				</DragDropContext>
			</Flex>
			{renderHelperText()}

			<Modal
				isOpen={isOpen}
				onClose={onClose}
				header="Foto"
				isCentered
				contentStyles={{
					width: 600,
				}}
			>
				<ChakraImage py={10} src={selectedImage} alt="" />
			</Modal>
		</FormControl>
	);
};

MultipleImages.defaultProps = {
	maxLength: 6,
	value: [],
};

export default MultipleImages;
