/* eslint-disable react/display-name */
import React, {
	forwardRef,
	MutableRefObject,
	ReactElement,
	RefAttributes,
} from 'react';
import { GroupBase, SelectInstance } from 'react-select';
import ReactSelectCreatable, { CreatableProps } from 'react-select/creatable';

import { useColorMode } from '@ctlyst.id/internal-ui';

import { selectStyles, themeSelect } from './utils';

export type CreatableSelectComponent = <
	Option = unknown,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>
>(
	props: CreatableProps<Option, IsMulti, Group> &
		RefAttributes<SelectInstance<Option, IsMulti, Group>>
) => ReactElement;

const SelectCreatable = forwardRef(
	<Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
		{ styles, ...rest }: CreatableProps<Option, IsMulti, Group>,
		ref:
			| ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
			| MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
			| null
	) => {
		const { colorMode } = useColorMode();
		return (
			<ReactSelectCreatable
				ref={ref}
				{...(rest as CreatableProps<Option, IsMulti, Group>)}
				styles={{
					...selectStyles(colorMode),
					...styles,
				}}
				theme={themeSelect}
			/>
		);
	}
) as CreatableSelectComponent;

export default SelectCreatable;
