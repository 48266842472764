import { useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import { ChevronDown, Close } from '@ctlyst.id/internal-icon';
import {
	Box,
	Button,
	InputField as Input,
	InputGroup,
	InputRightElement,
	ModalFooter,
	Radio,
	RadioGroup,
	Stack,
	useDisclosure,
} from '@ctlyst.id/internal-ui';
import { formatDate } from '@utils';
import { addDays, format } from 'date-fns';
import { id } from 'date-fns/locale';

import { Modal } from '@components/modal';

import Styles from './styles';

registerLocale('id', id);

export type onChangeProps = { startDate: string; endDate: string };

interface DatePickerProps {
	placeholder?: string;
	onChange: (props: onChangeProps) => void;
	disabled?: boolean;
	isDisableAll?: boolean;
	maxDate?: Date;
	minDate?: Date;
}
type radioType = 'all' | 'today' | 'lastWeek' | 'thisMonth' | 'customDate';

type stateType = {
	label: string;
	startDate: Date | null;
	endDate: Date | null;
	type?: radioType;
};

const defaultDate = {
	startDate: null,
	endDate: null,
};

const initialState: stateType = {
	label: '',
	startDate: null,
	endDate: null,
};

const DatePickerField = ({
	onChange,
	placeholder = 'Tanggal dibuat',
	disabled,
	isDisableAll = true,
	maxDate = new Date(),
	minDate,
}: DatePickerProps) => {
	const [value, setValue] = useState<stateType>(initialState);
	const [current, setCurrent] = useState<stateType>(initialState);
	const datePickerModal = useDisclosure({ defaultIsOpen: false });
	const viewCalender = useDisclosure({ defaultIsOpen: false });

	const startDate = current.startDate ? new Date(current.startDate) : null;
	const endDate = current.endDate ? new Date(current.endDate) : null;

	const showRemove = !!value.startDate && !!value.endDate;
	const disableSubmitButton = !current.startDate || !current.endDate;

	function handleRadio(type: radioType) {
		let _startDate: Date | null = null;
		let _endDate: Date | null = null;
		switch (type) {
			case 'all':
				setCurrent({
					label: 'All Time',
					startDate: null,
					endDate: null,
					type,
				});
				viewCalender.onClose();
				break;
			case 'today':
				const dateNow = new Date();
				_startDate = dateNow;
				_endDate = dateNow;
				setCurrent({
					label: 'Hari ini',
					startDate: _startDate,
					endDate: _endDate,
					type,
				});
				viewCalender.onClose();
				break;
			case 'lastWeek':
				const weekBefore = addDays(new Date(), -7);
				const now = new Date();
				_startDate = weekBefore;
				_endDate = now;
				setCurrent({
					label: 'Seminggu terakhir',
					startDate: _startDate,
					endDate: _endDate,
					type,
				});
				viewCalender.onClose();
				break;
			case 'thisMonth':
				const dateToday = new Date();
				const monthBefore = new Date(
					dateToday.getFullYear(),
					dateToday.getMonth(),
					1
				);
				const endDateOfThisMonth = new Date(
					dateToday.getFullYear(),
					dateToday.getMonth() + 1,
					0
				);
				_startDate = monthBefore;
				_endDate = endDateOfThisMonth;
				setCurrent({
					label: 'Bulan ini',
					startDate: _startDate,
					endDate: _endDate,
					type,
				});
				viewCalender.onClose();
				break;
			case 'customDate':
				setCurrent(prev => ({ ...prev, ...defaultDate }));
				viewCalender.onOpen();
				break;
		}
	}

	function onDateChange(date: [Date | null, Date | null]) {
		const label =
			date[0] && date[1]
				? `${formatDate(date[0], 'd MMM yyyy')} - ${formatDate(
						date[1],
						'd MMM yyyy'
				  )}`
				: '';

		setCurrent({
			label,
			startDate: date[0],
			endDate: date[1],
			type: 'customDate',
		});
	}

	function closeModalHandler() {
		datePickerModal.onClose();
		viewCalender.onClose();
		setCurrent(value);
	}

	function onClear() {
		setCurrent(initialState);
		setValue(initialState);
		onChange({ startDate: '', endDate: '' });
	}

	function openModal() {
		datePickerModal.onOpen();
		if (value.type === 'customDate') {
			viewCalender.onOpen();
		}
	}

	function onSubmit() {
		setValue(current);
		viewCalender.onClose();
		datePickerModal.onClose();
		onChange({
			startDate: format(new Date(current.startDate || ''), 'd MMM yyyy'),
			endDate: format(new Date(current.endDate || ''), 'd MMM yyyy'),
		});
	}

	return (
		<>
			<Box>
				<InputGroup borderColor="gray.300">
					<Input
						value={value.label}
						onMouseDown={openModal}
						placeholder={placeholder}
						isDisabled={disabled}
						_placeholder={{
							color: 'black.low',
						}}
					/>
					{!showRemove && (
						<InputRightElement
							pt="2"
							children={<ChevronDown size={4} color="gray.300" />}
						/>
					)}
					{showRemove && (
						<InputRightElement
							children={
								<Box
									data-test-id="CT_component_date-picker-field_clear-button"
									cursor="pointer"
									mt="10px"
									onClick={onClear}
								>
									<Close size={4} color="gray.300" />
								</Box>
							}
						/>
					)}
				</InputGroup>
			</Box>

			<Modal
				isOpen={datePickerModal.isOpen}
				onClose={closeModalHandler}
				header="Periode Tanggal"
				isCentered
				contentStyles={{
					width: 600,
				}}
				footer={
					<ModalFooter display="flex" justifyContent="flex-end">
						<Button
							data-test-id="CT_component_date-picker-field_cancel-button"
							variant="outline"
							onClick={closeModalHandler}
							mr={2}
						>
							Batal
						</Button>
						<Button
							data-test-id="CT_component_date-picker-field_submit-button"
							onClick={onSubmit}
							disabled={disableSubmitButton}
						>
							Terapkan
						</Button>
					</ModalFooter>
				}
			>
				<Box>
					<RadioGroup
						label=""
						flexDirection="column"
						gap="3"
						defaultValue={value.type}
						onChange={handleRadio}
						color="black.high"
						data-test-id="CT_component_date-picker-field_radio-group"
					>
						<Stack>
							{!isDisableAll && (
								<Radio value="all" mb={1}>
									All Time
								</Radio>
							)}
							<Radio value="today" mb={1}>
								Hari ini
							</Radio>
							<Radio value="lastWeek" mb={1}>
								Seminggu terakhir
							</Radio>
							<Radio value="thisMonth" mb={1}>
								Bulan ini
							</Radio>
							<Radio value="customDate" mb={1}>
								Pilih periode tanggal
							</Radio>
						</Stack>
					</RadioGroup>

					{viewCalender.isOpen && (
						<Box
							mt={4}
							display="flex"
							justifyContent="space-between"
							shadow="lg"
							rounded="lg"
							sx={{
								'.react-datepicker': {
									filter: 'none',
									width: '100%',
									display: 'flex',
									flexDir: 'row',
									top: 0,
									alignItems: 'start',
									'.react-datepicker__month-container': {
										width: '70%',
									},
								},
								'&>div': {
									width: '100%',
								},
							}}
						>
							<Styles />
							<ReactDatePicker
								inline
								locale="id"
								onChange={onDateChange}
								startDate={startDate}
								endDate={endDate}
								maxDate={maxDate}
								minDate={minDate}
								shouldCloseOnSelect
								selectsRange
								monthsShown={2}
								data-test-id="CT_component_date-picker-field_picker"
								renderCustomHeader={({
									monthDate,
									customHeaderCount,
									decreaseMonth,
									increaseMonth,
								}) => (
									<div>
										<span className="react-datepicker__current-month">
											{monthDate.toLocaleString('id-ID', {
												month: 'long',
												year: 'numeric',
											})}
										</span>
										<button
											data-test-id="CT_component_date-picker-field_previous-month"
											aria-label="Previous Month"
											className="react-datepicker__navigation react-datepicker__navigation--previous"
											style={{
												visibility:
													customHeaderCount === 1 ? 'hidden' : 'visible',
												top: -6,
											}}
											onClick={decreaseMonth}
										>
											<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
												{'<'}
											</span>
										</button>
										<button
											data-test-id="CT_component_date-picker-field_next-month"
											aria-label="Next Month"
											className="react-datepicker__navigation react-datepicker__navigation--next"
											style={{
												visibility:
													customHeaderCount === 0 || monthDate > new Date()
														? 'hidden'
														: 'visible',
												top: -6,
											}}
											onClick={increaseMonth}
										>
											<span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
												{'>'}
											</span>
										</button>
									</div>
								)}
							/>
						</Box>
					)}
				</Box>
			</Modal>
		</>
	);
};

export default DatePickerField;
