/* eslint-disable react/display-name */
import React, {
	forwardRef,
	MutableRefObject,
	ReactElement,
	RefAttributes,
} from 'react';
import { GroupBase, SelectInstance } from 'react-select';
import { AsyncPaginate, AsyncPaginateProps } from 'react-select-async-paginate';

import { useColorMode } from '@ctlyst.id/internal-ui';

import { selectStyles, themeSelect } from './utils';

export type SelectAsyncProps = <
	OptionType = unknown,
	Group extends GroupBase<OptionType> = GroupBase<OptionType>,
	Additional = unknown,
	IsMulti extends boolean = false
>(
	props: AsyncPaginateProps<OptionType, Group, Additional, IsMulti> &
		RefAttributes<SelectInstance<OptionType, IsMulti, Group>>
) => ReactElement;

const SelectAsync = forwardRef(
	<
		OptionType,
		Group extends GroupBase<OptionType>,
		Additional,
		IsMulti extends boolean
	>(
		{
			styles,
			...props
		}: AsyncPaginateProps<OptionType, Group, Additional, IsMulti>,
		ref?:
			| ((instance: SelectInstance<OptionType, IsMulti, Group> | null) => void)
			| MutableRefObject<SelectInstance<OptionType, IsMulti, Group> | null>
			| null
	) => {
		const { colorMode } = useColorMode();
		return (
			<AsyncPaginate
				selectRef={ref}
				styles={{
					...selectStyles(colorMode, !!props['aria-invalid']),
					...styles,
				}}
				theme={themeSelect}
				{...props}
			/>
		);
	}
) as SelectAsyncProps;

export default SelectAsync;
