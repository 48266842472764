/* eslint-disable @typescript-eslint/no-explicit-any */

export const reorderArray = (
	list: any[],
	startIndex: number,
	endIndex: number
) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};
