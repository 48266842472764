/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import getRuntimeEnv from '@utils/env';
import { CookieValueTypes } from 'cookies-next';

import { useAuthContext } from './auth-provider';

interface Props {
	children?: ReactNode;
}

export const PrivateRoute = ({ children }: Props) => {
	const { isAuthenticated } = useAuthContext();
	const [isProtected, setProtected] = useState<CookieValueTypes>(false);
	const { replace } = useRouter();

	const xmsLoginUrl = getRuntimeEnv('XMS_URL');

	const redirectUrl =
		getRuntimeEnv('APP_ENV') === 'local' ? '/login' : `${xmsLoginUrl}/login`;

	useEffect(() => {
		setProtected(isAuthenticated);
		if (!isAuthenticated) {
			replace(redirectUrl || '');
		}
	}, [isAuthenticated, replace]);

	if (!isProtected) {
		return null;
	}

	return <>{children}</>;
};

export const PublicRoute = ({ children }: Props) => {
	const { isAuthenticated } = useAuthContext();
	const [isProtected, setProtected] = useState<CookieValueTypes>(true);
	const { replace } = useRouter();

	useEffect(() => {
		setProtected(isAuthenticated);
		if (isAuthenticated) {
			replace(`/home`);
		}
	}, [isAuthenticated]);

	if (isProtected) {
		return null;
	}

	return <>{children}</>;
};
