import getRuntimeEnv from '@utils/env';
import { getCookie, removeCookies, setCookies } from 'cookies-next';

import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants';

export function getDomain() {
	let domain = window.location.hostname;
	const hostname = domain.split('.');
	if (hostname.length > 2) {
		hostname.slice(0, hostname.length - 3);
		hostname[0] = '';
		domain = hostname.join('.');
	}
	return domain;
}
export function onLogin(accessToken: string, refreshToken: string): void {
	const options = {
		domain: getDomain(),
	};
	setCookies(ACCESS_TOKEN, accessToken, options);
	setCookies(REFRESH_TOKEN, refreshToken, options);
	window.location.href = '/home';
}

export function onLogout(): void {
	const options = {
		domain: getDomain(),
	};
	removeCookies(ACCESS_TOKEN, options);
	removeCookies(REFRESH_TOKEN, options);
	const xmsLoginUrl = getRuntimeEnv('XMS_URL');
	const url =
		process.env.NODE_ENV === 'development'
			? '/login'
			: `${xmsLoginUrl}/login?redirect=cms`;
	window.location.href = url;
}

export const getAccessToken = (): string =>
	getCookie(ACCESS_TOKEN)?.toString() ?? '';

export const getRefreshToken = (): string =>
	getCookie(REFRESH_TOKEN)?.toString() ?? '';
