import React, {
	forwardRef,
	MutableRefObject,
	Ref,
	useEffect,
	useRef,
} from 'react';

import { CheckboxProps } from '@chakra-ui/react'; // need reexport from ctlyst ui
import { Checkbox } from '@ctlyst.id/internal-ui';

interface IndeterminateCheckboxProps extends CheckboxProps {
	isIndeterminate?: boolean;
	name: string;
}

const useCombinedRefs = (...refs: any[]): MutableRefObject<any> => {
	const targetRef = useRef();

	useEffect(() => {
		refs.forEach(ref => {
			if (!ref) return;

			if (typeof ref === 'function') {
				ref(targetRef.current);
			} else {
				ref.current = targetRef.current;
			}
		});
	}, [refs]);

	return targetRef;
};

const IndeterminateCheckbox = forwardRef<
	HTMLInputElement,
	IndeterminateCheckboxProps
>(({ isIndeterminate, isChecked, ...rest }, ref: Ref<HTMLInputElement>) => {
	const defaultRef = useRef<HTMLInputElement | null>(null);
	const combinedRef = useCombinedRefs(ref, defaultRef);

	useEffect(() => {
		if (combinedRef?.current) {
			combinedRef.current.indeterminate = isIndeterminate ?? false;
		}
	}, [combinedRef, isIndeterminate]);

	return (
		<Checkbox
			ref={combinedRef}
			focusBorderColor="0"
			isIndeterminate={isIndeterminate}
			isChecked={isChecked}
			inputProps={
				{
					'data-test-id': `CT_component_indeterminate-checkbox_${
						rest.id || rest.name
					}`,
				} as any
			}
			{...rest}
		/>
	);
});

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export default IndeterminateCheckbox;
