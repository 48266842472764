import getConfig from 'next/config';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

export function getServerRuntimeEnv(
	key: string,
	defaultValue?: string
): string {
	return serverRuntimeConfig[key as string] || defaultValue;
}

export default function getRuntimeEnv(
	key: string,
	defaultValue?: string
): string {
	return publicRuntimeConfig[key as string] || defaultValue;
}

export function getPrefixEnv(env: string) {
	switch (env) {
		case 'development':
			return 'dev-';

		case 'staging':
			return 'stg-';

		default:
			return '';
	}
}
