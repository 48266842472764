import { format } from 'date-fns';
import { id } from 'date-fns/locale';

export const formatDate = (
	date: string | Date,
	formatString: string,
	withLocale = true
) => {
	let dateValue: Date;
	if (typeof date === 'string') {
		dateValue = new Date(date);
	} else {
		dateValue = date;
	}
	return format(dateValue, formatString, {
		locale: withLocale ? id : undefined,
	});
};

export const formatDateUTC = (
	date: string | Date,
	formatString: string,
	withLocale = true
) => {
	let dateValue: Date;
	if (typeof date === 'string') {
		dateValue = new Date(date.replace(' ', 'T').replace('Z', '')); // support old safari cause it doens't support ISO8601
	} else {
		dateValue = date;
	}
	return format(dateValue, formatString, {
		locale: withLocale ? id : undefined,
	});
};
